import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function MagicGlassButtons(props) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Progression</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeImage("Kangaroo")}
            active={props.currImage === "Kangaroo"}
          >
            Level 1: Kangaroo Magic
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeImage("Stacking")}
            active={props.currImage.includes("Stacking")}
          >
            Level 2: Stacking Animals
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
