import ShadowPuppetOptions from "./ShadowPuppets";
import { instructionMap } from "./ShadowPuppetsData";
import { useEffect, useState } from "react";

export default function Level3_ShadowPuppets(props) {
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("Dog");
  var [dirPath, setDirPath] = useState("/VisuallyDirectedFineMotor/ShadowPuppets/");

  const [level, setLevel] = useState("Level1");
  const [display, setDisplay] = useState(0);
  const [next, setNext] = useState(false);
  const [place, setPlace] = useState(0)

  // const Level4 = [
  //   "Crocodile",
  //   "Dinosaur",
  //   "Wombat",
  //   "Pengunin",
  //   "Frilled Lizard",
  //   "Emu",
  //   "Dog",
  // ];
  const Level1 = ["Dog", "Butterfly"];
  const Level2 = ["Duck", "Dogs", "Crab", "Jellyfish", "Kookaburra", "Croc"];
  // const Level3 = ["Pelican", "Kangaroo", "Sea Tutle", "Sugar Glider", "Dingo"];

  function preLoadImages() {
    for (let i = 0; i < 2; i++) {
      if (i === 0) {
        loadLevel("Level1", Level1);
      }
      if (i === 1) {
        loadLevel("Level2", Level2);
      }
    }
  }

  function loadLevel(level, levelList) {
    for (let i = 0; i < levelList.Length; i++) {
      const animal = levelList[i]
      const img = new Image();
      img.src =
        dirPath + level + "/" + animal + ".png";
      for (let i = 1; i < 5; i++) {
        if (instructionMap[animal + JSON.stringify(i)]) {
          const img2 = new Image();
          img2.src = dirPath + level + "/" + animal + JSON.stringify(i) + ".png"
        }

      }
    }
  }

  useEffect(() => {
    preLoadImages();
  }, []);

  useEffect(() => {
    setDisplay(0);
    setNext(false);
    // setPlace(0);
    
  }, [level, props.open]);

  function displayPicture(place) {
      console.log("this is the place: ", place)
      if (level === "Level1") {
        if (place === Level1.length - 1) {
          setImage(Level1[place])
          setDisplay(1);
          setPlace(0)
        } else {
          setImage(Level1[place])
          setDisplay(1);
          setPlace(place + 1)
        }
      } else {
        if (place === Level2.length - 1) {
          setImage(Level2[place])
          setDisplay(1);
          setPlace(0)
        } else {
          setImage(Level2[place])
          setDisplay(1);
          setPlace(place + 1)
        }
      }
      // setNext(true);
  }

  function calcWidth() {
    if (image === "Croc") {
      return "50%"
    }
    if (instructionMap[image + "4"]) {
      return "25%"
    }
    if (instructionMap[image + "3"]) {
      return "33%"
    }
    
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <ShadowPuppetOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay="none"
          changeImage={setImage}
          imageBool={image}
          changePdf={props.changePdf}
          setPlace={setPlace}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {display === 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <img
                style={{height: '100%'}}
                src={dirPath + level + '/' + image + '.png'}//{dirPath + "/" + level + "/" + image + ".png"}
              />
              {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', height: '50%', width:'100%'}}>
                <div style={{height: '100%', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: calcWidth()}}>
                  <div><b>Step: 1</b></div>
                  <img style={{height: '70%'}} src={dirPath + "/" + level + "/" + image + "1" + ".png"}/>
                  <div style={{display: 'flex', textAlign: 'center', padding: '5px'}}>{instructionMap[image + "1"]}</div>
                </div>
                <div style={{height: '100%', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: calcWidth()}}>
                  <div><b>Step: 2</b></div>
                  <img style={{height: '70%'}} src={dirPath + "/" + level + "/" + image + "2" + ".png"}/>
                  <div style={{display: 'flex', textAlign: 'center', padding: '5px'}}>{instructionMap[image + "2"]}</div>
                </div>
                {instructionMap[image + "3"] && <div style={{height: '100%', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: calcWidth()}}>
                  <div><b>Step: 3</b></div>
                  <img style={{height: '70%'}} src={dirPath + "/" + level + "/" + image + "3" + ".png"}/>
                  <div style={{display: 'flex', textAlign: 'center', padding: '5px'}}>{instructionMap[image + "3"]}</div>
                </div>}
                {instructionMap[image + "4"] && <div style={{height: '100%', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: calcWidth()}}>
                  <div><b>Step: 4</b></div>
                  <img style={{height: '70%'}} src={dirPath + "/" + level + "/" + image + "4" + ".png"}/>
                  <div style={{display: 'flex', textAlign: 'center', padding: '5px', alignItems: 'f'}}>{instructionMap[image + "4"]}</div>
                </div>}
              </div> */}
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture(place);
            } else {
              props.openActivity(true);
            }
          }}
          // disabled={next}
        >
          Next
        </button>
      </div>
    </div>
  );
}
