export const sentence1 =
  "One Saturday afternoon Mother called to us kids from the front porch, holding a huge round bowl. My mouth watered, imagining an extra serving of quince pie for dinner. Just about that time, I sneezed and spat out my chewing gum.";
export const sentence2 =
  "Playing music makes me feel extra joyful. I love to make up my own dance steps. Sometimes I pretend I am on stage, before adoring crowds of fans who sing along with me. When I am done I leave quickly on my airplane, fly home to just relax and be lazy with friends and family.";
export const sentence3 =
  "One day I was joyfully riding my bike along the old track when a fox stepped out from behind a tree and zapped me with a funny look in her eyes. Stopping quickly, I got off my bike and asked her what she wanted. She said she was very tired and wanted to know if I could give her a lift.";
export const sentence4 =
  "Visiting the zoo with my friends was exceptional. We saw the animal keepers feed the quiet zebras and noisy chimpanzees. We also discovered that a group of gorillas is called a band. I just hope to return one day very soon, at least before I grow much older.";
export const sentence5 =
  "I was just dozing quietly outside on the hammock in our yard one hot summer day when my young cousin ran past, casting a shadow on the ground. Water hit my back and I turned to see her holding a garden hose high above our extra fuzzy and dirty dog.";
export const sentence6 =
  "A very lazy boy swung in the hammock, picking his extra big nose with joy. He quietly rolled the snot into cannonballs and flicked them at his dad.";
export const sentence7 =
  "We rescued an ex-racing dog who just loves to be lazy. He is a skinny greyhound and we love to dress him up in warm purple sweaters.  But when he runs, he is so quick we get exhausted before we can catch him.";
export const sentence8 =
  "One quiet morning I woke up to find a box lying next to my bed. I was curious, because it was not there when I went to sleep last night. I was surprised to hear noises coming from inside, so I lifted the lid and gazed down to see my new puppy.";
export const sentence9 =
  "I stayed up very late to watch the amazing lunar eclipse. I learnt in a school quiz that when the Earth blocks the sunlight exactly, it casts a shadow on the moon. I expected the moon to disappear but unfortunately it just got red.";
export const sentence10 =
  "Koalas are fuzzy and very cute. They live entirely in gum trees, I don’t know exactly how many live near me because they are so quiet. However I just learnt that they have terrible bad breath from the eucalyptus leaves and can be quite cranky.";
