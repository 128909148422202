import DoubleVisionButtons from "./DoubleVisionButtons";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function Level6_MagicGlasses(props) {
  var [image1, setImage1] = useState("Caterpillar");
  var [dirPath, setDirPath] = useState("/BuildingBinocularity/MagicGlasses/");
  const [duration, setDuration] = useState(2);
  const [play, setPlay] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <DoubleVisionButtons changeImage={setImage1} currImage={image1} />
      </div>
      {props.open && (
        <div
          className="vertCenter"
          style={{ height: "85%", width:'100%', display: "flex", justifyContent: "center" }}
        >
          {image1 === "Caterpillar" && (
            <div style={{ textAlign: "center", height: '100%'}}>
              <img
                src="/BuildingBinocularity/Caterpillar.png"
                style={{ height: "100%" }}
              />
            </div>
          )}
          {image1 === "Seesaw" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: 'center'
              }}
            >
              { play ?
               <motion.div
                className="seesaw"
                animate={{
                  rotate: [-15, 15, -15],
                }}
                transition={{
                  duration: duration,
                  repeat: Infinity,
                  // ease: 'easeInOut'
                }}
              />
              :
              <div 
                className="seesaw"
              />}
              <div className="halfCircle"></div>
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        {image1 === "Seesaw" && (
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              setPlay(!play);
            } else {
              props.openActivity(true);
            }
          }}
          disabled={image1 === "Kangaroo"}
        >
          {play ? "Pause" : "Play"}
        </button>
        )}
      </div>
    </div>
  );
}
