import MagicGlassButtons from "./MagicGlassButtons";
import { useEffect, useState } from "react";

export default function Level6_MagicGlasses(props) {
  var [image1, setImage1] = useState("Kangaroo");
  var [dirPath, setDirPath] = useState("/BuildingBinocularity/MagicGlasses/");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <MagicGlassButtons changeImage={setImage1} currImage={image1} />
      </div>
      {props.open && (
        <div
          // className="vertCenter"
          style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", maxHeight: '90%'}}
        >
          <div
            style={{
              height: "100%",
              maxHeight: "100%",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              // backgroundColor: "blue"
            }}
          >
            <img
              src={dirPath + image1 + ".png"}
              style={{ height: "100%", maxHeight: "100%", maxWidth: "100%"}}
            />
          </div>
          {image1 === "Stacking" && <div style={{height: '100%', display: 'flex', alignItems: 'flex-end'}}>
            <button className="activityActionButton" onClick={() => setImage1(image1 + "Answer")}>Check</button>
          </div>}
        </div>
      )}
    </div>
  );
}
