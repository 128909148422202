export default function NextCheckButtons(props) {
  return (
    <div class="activityActionButtonsContainer">
      <button
        class="activityActionButton"
        onClick={() => {
          if (props.open) {
            props.displayFunc("check");
          } else {
            props.openActivity(true);
          }
        }}
        style={{ display: props.displayCheck }}
        disabled={props.check}
      >
        Check
      </button>
      <button
        class="activityActionButton"
        onClick={() => {
          if (props.open) {
            props.displayFunc("next");
          } else {
            props.openActivity(true);
          }
        }}
        disabled={props.disableNext}
      >
        Next
      </button>
    </div>
  );
}
