import "./Tutorials.css";
import { Nav } from "../components/home-page/nav-section";
import { Footer } from "../components/home-page/footer-section";
import { useState, useEffect } from "react";

export default function Tutorials() {
    const videos = [
        {name:"Mirror World",category:"Building Binocularity",level:"3",embedId:"1bqDx8pOhvs"},
        {name:"Bean Bag Toss",category:"Building Binocularity",level:"3",embedId:"DEa0WI30gWE"},
        {name:"Double Vision",category:"Building Binocularity",level:"4",embedId:"HqjVwBKeP7M"},
        {name:"Join the Circus",category:"Visually Directed Fine Motor",level:"5",embedId:"0Y9gyjHnEeI"},
      ]
  return (
    <div id="outerContainer">
      <Nav />
      <div style={{ maxWidth: "1200px" }}>
        <h1 className="sectionHeader">Tutorials</h1>
        {videos.map((video, index) => (
          <div key={index} className="videoContainer">
            {/* Displaying video name and URL for debugging */}
            <h3>{video.name+" - "+video.category+" - Level "+video.level}</h3>
            {/* Embedding video */}
            <iframe
              width="560"
              height="315"
              src={"https://youtube.com/embed/"+video.embedId+"?rel=0"}
              title={video.name}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
