import "./Purchase.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth.js";
import { SyncLoader } from "react-spinners";
import { AiOutlineLeft as BackIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { writeBatch, doc, getDoc, collection, arrayUnion } from "firebase/firestore";
import { db } from "../base.js";
import { sendEmail } from "../utils/sendEmail";
import { getCheckoutUrl, stripePriceIDs } from "../functions/stripeCheckout.js";

export default function Purchase() {
  const { currentUser } = useContext(AuthContext);
  const [checkoutString,setCheckoutString] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copies, setCopies] = useState(0);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);
  
  async function initiateCheckout(){
    setLoading(true)
    console.log("initiating checkout1 ", copies)
    const prices = []
    let item = "individual_copy"
    if(copies >= 6){
      item="group_copy"
    }     
    const priceData = {
      price: stripePriceIDs[item]["id"],
      quantity: copies
    }
    let total = stripePriceIDs[item]["price"]*copies
    let fee = calculateFee(total)
    const checkoutURL = await getCheckoutUrl([priceData],fee, copies)
    console.log("Checkout URL Returned")
    console.log(checkoutURL)
    setLoading(false)
    window.location.href=checkoutURL
  }

  function calculateFee(price) {
    let totalWithFee = (price + .3) / (1 - .029)
    let fee = totalWithFee - price
    return fee   
}

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <>
      <div id="purchaseHeader">
        <div id="purchaseHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
            Purchase Piggyback
          </h1>
          <p
            style={{
              fontFamily: "Mulish",
              fontSize: "17px",
              fontWeight: "700",
            }}
          >
            Please select the items that you would like to purchase.
          </p>
        </div>
        <div onClick={() => navigate("/")} id="providerScreenBackButton">
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Back</p>
        </div>
      </div>
      <div  id="purchaseForm">
        <p
          style={{ fontFamily: "Mulish", fontSize: "17px", fontWeight: "700" }}
        >
          PiggyBack Copies
        </p>
        <div className="purchaseInputContainer">
          <label>Individual Copies</label>
          <div className="purchaseCountInputContainer">
            <div style={{ textAlign: "end" }}>
              <p>{copies < 6 ? "$150 each" : "$135 each"}</p>
            </div>
            <input
              name="copies"
              min={0}
              value={copies}
              onChange={(e) => setCopies(e.target.value)}
              className="countInput"
              type="number"
            ></input>
          </div>
        </div>
        <p>Checking out: {checkoutString}</p>
        <div id="submitButtonContainer">
          <p>Continue to payment methods.</p>
          {copies < 6 && (
            <p style={{ color: "red" }}>
              Add {6 - copies} more licenses to pay $135 each!
            </p>
          )}
          <div><i>Piggyback is not being sold at this time</i></div>
          <button disabled={true} onClick={initiateCheckout} type="submit" id="purchaseSubmitButton">
            {loading ? "Loading" : "Next"}
          </button>
        </div>
      </div>
    </>
  );
}
