export const activityNames = [
  "Picture Codes",
  "Clap Patterns",
  "Sound Codes",
  "Pointing",
  "Arrows",
  "Memory Dots",
  "Angels in the Snow",
  "Ball Bounce",
  "B, d, p, and q",
  "Halopin Hands",
  "Look in the Middle",
  "Halopin Hands 2",
  "Feel & Match",
  "Find the Picture",
  "Flashing Pictures",
  "Flashing Words",
  "Visual Sequence",
  "Flashing Dot Patterns",
  "Tangrams",
  "Flashing Shapes",
  "Word Twins",
  "Visual Sequence 2",
  "Say My Name",
  "Tangrams 2: The Sequel",
  "Flashing Blocks",
  "Flip & Rotate Shapes",
  "Describing Pictures",
  "Look Closely",
  "Picture Match",
  "Koala Copy",
  "Join the Circus",
  "Letter LeapFrog Charts",
  "Infinity Tracking",
  "Letter LeapFrog Songs",
  "Counting Letters & Words",
  "Letter LeapFrog Tales",
  "Finding Letters",
  "Bean Bag Swing",
  "Torch Tag",
  "Ball on String",
  "Walk the Plank",
  "Mirror World",
  "Bean Bag Toss",
  "3D Pictures",
  "Double Vision",
  "Beads on a String",
  "Magic Glasses",
  "Feed the Animals",
  "X-Ray Vision",
  "Super Vision",
  "Matching Colours",
  "Animal Shapes",
  "Pegboard Match",
  "Follow the Line",
  "Shadow Puppets",
  "Mazes",
  "Origami",
];

export const nameMapping={
  "B, d, p, and q":"bdpq",
  "Flipping & Rotating Shapes":"Flip&RotateShapes",
  "See and Say Pictures":"PictureMatch",
  "Letter LeapFrog Croaky Charts":"LetterLeapFrogCharts",
  "Maze":"Mazes",
  "Flashing Dot to Dot Patterns": "FlashingDotPatterns"
}

export const tableObject = {
  "Coding":{
    "Level 1": ["Picture Codes","Clap Patterns"],
    "Level 2": ["Sound Codes","Pointing"],
    "Level 3": ["Arrows","Memory Dots"],
    "Level 4": ["Angels in the Snow"],
    "Level 5": ["Ball Bounce","B, d, p, and q"],
    "Level 6": ["Halopin Hands"],
    "Level 7": ["Look in the Middle","Halopin Hands 2"],
  },
  "Visualization":{
    "Level 1": ["Feel & Match","Flashing Pictures"],
    "Level 2": ["Visual Sequence","Find the Picture"],
    "Level 3": ["Flashing Shapes","Flashing Words"],
    "Level 4": ["Flashing Dot to Dot Patterns"],
    "Level 5": ["Tangrams","Word Twins"],
    "Level 6": ["Flashing Blocks","Tangrams 2 The Sequel","Say My Name"],
    "Level 7": ["Flipping & Rotating Shapes","Describing Pictures"]
  },
  "Eye Movements":{
    "Level 1":["Look Closely","Picture Memory"],
    "Level 2":["Infinity Tracking","Koala Copy"],
    "Level 3":["Letter LeapFrog Croaky Charts"],
    "Level 4":["Letter LeapFrog Songs"],
    "Level 5":["Counting Letters & Words"],
    "Level 6":["Letter Leap Frog Tales"],
    "Level 7":["Finding Letters"],
  },
  "Building Binocularity":{
    "Level 1":["Bean Bag Swing","Torch Tag"],
    "Level 2":["Ball on String","Walk the Plank"],
    "Level 3":["Mirror World","Bean Bag Toss"],
    "Level 4":["3D Pictures","Double Vision"],
    "Level 5":["Beads on a String","Feed the Animals"],
    "Level 6":["Magic Glasses","X-Ray Vision"],
    "Level 7":["Super Vision"]
  },
  "Visually Directed Fine Motor":{
    "Level 1":["Matching Colours","Animal Shapes"],
    "Level 2":["Pegboard Match"],
    "Level 3":["Shadow Puppets"],
    "Level 4":["Follow the Line"],
    "Level 5":["Join the Circus"],
    "Level 6":["Maze"],
    "Level 7": ["Origami"]
}
}
