import "./Activity.css";
import { useNavigate } from "react-router-dom";
import LeftDrawer from "../components/drawers/LeftDrawer";
import BottomRightDrawer from "../components/drawers/BottomRightDrawer";
import UpperRightDrawer from "../components/drawers/UpperRightDrawer";
import { Document, Page, pdfjs } from "react-pdf";
import ActivityContainer from "../components/ActivityContainer";
import { useEffect, useState, useContext } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { noActivity } from "../data/noActivityList";
import { AuthContext } from "../Auth.js";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Activity() {
  const { currentUser, authUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [pdf, setPdf] = useState("/piggybackHomePage.pdf");
  const [pdf2, setPdf2] = useState("/HELPLandingPage.pdf");
  const [hide, setHide] = useState("block");
  const [LUPDF, setLUPDF] = useState(
    "/VisuallyDirectedFineMotor/OrigamiLU.pdf",
  );

  useEffect(() => {
    if ((!currentUser||authUserData==undefined||authUserData==null) || ((authUserData.license==undefined || authUserData.license=="") && authUserData.userType!="admin")) {
      navigate("/login");
    }
    else if (noActivity.includes(pdf)) {
      setHide("none");
    } else {
      setHide("block");
    }
  }, [pdf,currentUser, navigate]);

  return (
    <div>
      <div id="header">
        <div
          onClick={() => navigate("/")}
          style={{
            cursor: "pointer",
            fontFamily: "NexaBold",
            fontSize: "26px",
          }}
        >
          Piggyback
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={() => navigate("/")}
        >
          <div
            style={{
              cursor: "pointer",
              fontFamily: "NexaBold",
              fontSize: "26px",
            }}
          >
            {"<"}
          </div>
          <div
            style={{
              cursor: "pointer",
              fontFamily: "NexaBold",
              fontSize: "26px",
            }}
          >
            Home
          </div>
        </div>
      </div>
      <div id="activitySection">
        <div style={{ width: "80px" }}>
          <LeftDrawer changePdf={setPdf} changePdf2={setPdf2} />
        </div>
        <div id="pdfView">
          <Document file={pdf} renderTextLayer={false}>
            <Page pageNumber={1} className="pageStyle" />
          </Document>
        </div>
        <div style={{ width: "600px", display: hide }}>
          <ActivityContainer
            activity={pdf}
            changePdf={setPdf2}
            changeLU={setLUPDF}
          />
        </div>

        <div id="rightDrawerSection">
          <UpperRightDrawer pdf={pdf.includes("Origami") ? LUPDF : pdf} />
          <BottomRightDrawer pdf={pdf2} />
        </div>
      </div>
    </div>
  );
}
