export const DoubleA = [
  ["One", "day", "I", "saw"],
  ["The", "old", "big", "dog"],
  ["Hop", "on", "the", "bed"],
  ["And", "eat", "a", "bug"],
];

export const DoubleB = [
  ["Get", "off", "my", "hat"],
  ["He", "said", "to", "me"],
  ["It", "looks", "too", "flat"],
  ["To", "hold", "the", "bee"],
];

export const DoubleC = [
  ["When", "I", "am", "sad"],
  ["Or", "get", "real", "mad"],
  ["I", "kick", "and", "yell"],
  ["So", "I", "feel", "glad"],
];

export const DoubleD = [
  ["She", "saw", "old", "gum"],
  ["Fall", "in", "the", "mud"],
  ["And", "got", "it", "out"],
  ["To", "eat", "the", "lot"],
];

export const SingleA = [
  ["I", "love", "our", "little", "pig"],
  ["Mum", "gives", "him", "old", "food"],
  ["He", "snorts", "and", "eats", "too", "fast"],
  ["And", "makes", "some", "stinky", "farts"],
];

export const SingleB = [
  ["Walking", "to", "school", "today"],
  ["I", "wished", "I", "had", "my", "bike"],
  ["To", "go", "with", "my", "friend"],
  ["And", "play", "in", "the", "mud"],
];

export const SingleC = [
  ["There", "is", "a", "sleepy", "cat"],
  ["Hiding", "in", "the", "long", "grass"],
  ["His", "fat", "tail", "is", "moving"],
  ["As", "he", "dreams", "of", "fish"],
];

export const SingleD = [
  ["Mum", "loves", "to", "climb", "trees"],
  ["And", "have", "a", "good", "think"],
  ["But", "she", "comes", "back", "down"],
  ["When", "she", "starts", "to", "stink"],
];
