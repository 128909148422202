import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function FlashingPictureButtons(props) {
  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Flash Rate
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.flashRate(250)}
            active={props.flashBool === 250}
          >
            0.25
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(500)}
            active={props.flashBool === 500}
          >
            0.50
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(600)}
            active={props.flashBool === 600}
          >
            0.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(700)}
            active={props.flashBool === 700}
          >
            0.70
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(800)}
            active={props.flashBool === 800}
          >
            0.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(900)}
            active={props.flashBool === 900}
          >
            0.90
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(1000)}
            active={props.flashBool === 1000}
          >
            1.00
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(1200)}
            active={props.flashBool === 1200}
          >
            1.20
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(1400)}
            active={props.flashBool === 1400}
          >
            1.40
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(1600)}
            active={props.flashBool === 1600}
          >
            1.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(1800)}
            active={props.flashBool === 1800}
          >
            1.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.flashRate(2000)}
            active={props.flashBool === 2000}
          >
            2.00
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Presentation
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.hiddenLetter("1Letter")}
            active={props.letterBool === "1Letter"}
          >
            1 Letter
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.hiddenLetter("small")}
            active={props.letterBool === "small"}
          >
            Small Words
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.hiddenLetter("big")}
            active={props.letterBool === "big"}
          >
            Big Words
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.levelDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Level
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeLevel("Level1")}
            active={props.levelBool === "Level1"}
          >
            Level 1: Static
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeLevel("Level2")}
            active={props.levelBool === "Level2"}
          >
            Level 2: Left to Right
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeLevel("Level3")}
            active={props.levelBool === "Level3"}
          >
            Level 3: Random
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeLevel("Level4")}
            active={props.levelBool === "Level4"}
          >
            Level 4: Random (whole Screen)
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
          disabled={props.durationDisplay}
        >
          Play Time
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeDuration(15)}
            active={props.durationBool === 15}
          >
            15 seconds
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeDuration(30)}
            active={props.durationBool === 30}
          >
            30 seconds
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeDuration(60)}
            active={props.durationBool === 60}
          >
            60 seconds
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
