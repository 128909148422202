export const AboutUsSection = () => {
  return (
    <div id="aboutUsSectionContainer">
      <div className="sectionHeader">About Us</div>
      <div>
        <p className="boldHeader">A custom approach to learning readiness.</p>
        <p className="aboutUsBodyText">
          We developed Piggyback from experience working with children and their
          families, combined with the latest neuroscience. Because sometimes we
          all need a little extra help.
        </p>
      </div>
      <div>
        <div className="aboutRow">
          <div className="bioBox">
            <div className="bioStyle">
              <p className="boldHeader">Optometrist Michael Smith</p>
              <p className="aboutUsBodyText">
                Michael developed Piggyback Classic 25 years ago for
                optometrists and vision therapists. Specialising in office-based
                vision therapy and childhood learning delay, he is
                internationally recognised for his expertise in visual
                assessment of children.
              </p>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <img src="/home-page/michael.png" />
            </div>
          </div>
          <div className="bioBox">
            <div style={{ width: "400px" }}>
              <p className="boldHeader">Physiotherapist Victoria Graham</p>
              <p className="aboutUsBodyText">
                Our USA trained researcher, physiotherapist, educator and
                co-author of the textbook Neuroscience for Rehabilitation. Vicky
                brings a unique experience as a special needs adoptee to her
                work as a vision therapist.
              </p>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <img src="/home-page/vicky.png" />
            </div>
          </div>
        </div>
        <div className="aboutRow">
          <div className="bioBox">
            <div className="bioStyle">
              <p className="boldHeader">Software Developer Dorien Simon</p>
              <p className="aboutUsBodyText">
                Stanford-educated sprinter Dorien combines excellence in
                computer science, creativity, and innovative leadership to
                support Piggyback.
              </p>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <img src="/home-page/dorien.png" />
            </div>
          </div>
          <div className="bioBox">
            <div style={{ width: "400px" }}>
              <p className="boldHeader">
                Web Designer and Artist Sammy Puckett
              </p>
              <p className="aboutUsBodyText">
                Stanford-educated artist and engineer Sammy brings her
                empathetic eye for cohesion and beauty to the project.
              </p>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <img src="/home-page/sammy.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
