import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";
import Home from "./screens/Home";
import Activity from "./screens/Activity";
import Purchase from "./screens/Purchase";
import ProviderInformation from "./screens/ProviderInformation";
import PatientInformation from "./screens/PatientInformation";
import Account from "./screens/Account";
import LoginScreen from "./screens/Login";
import Success from "./screens/Success"
import Tutorials from "./screens/Tutorials";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/account" element={<Account />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/success" element={<Success />} />
          <Route path="/provider-information" element={<ProviderInformation />} />
          <Route path="/patient-information" element={<PatientInformation />} />
          <Route path="/tutorials" element={<Tutorials />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
