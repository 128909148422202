import Dropdown from "react-bootstrap/Dropdown";
import {
  BlendsDouble,
  SmallWordsDouble,
  BlendTriple,
  SmallWordsTriple,
} from "./wordList";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function FlashingPictureButtons(props) {
  function handleWordListChange(change) {
    if (change === 2) {
      if (props.wordList === "small") {
        return SmallWordsDouble;
      }
      return BlendsDouble;
    } else if (change === 3) {
      if (props.wordList === "small") {
        return SmallWordsTriple;
      }
      return BlendTriple;
    } else if (change === "small") {
      if (props.number === 2) {
        return SmallWordsDouble;
      }
      return SmallWordsTriple;
    }
    if (props.number === 2) {
      return BlendsDouble;
    }
    return BlendTriple;
  }

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Flash Rate
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            active={props.flashBool === 0}
            onClick={() => {
              props.flashRate(0);
            }}
          >
            No Flash
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 1000}
            onClick={() => props.flashRate(1000)}
          >
            1.00
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 1200}
            onClick={() => props.flashRate(1200)}
          >
            1.20
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 1400}
            onClick={() => props.flashRate(1400)}
          >
            1.40
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 1600}
            onClick={() => props.flashRate(1600)}
          >
            1.60
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 1800}
            onClick={() => props.flashRate(1800)}
          >
            1.80
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 2000}
            onClick={() => props.flashRate(2000)}
          >
            2.00
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 2200}
            onClick={() => props.flashRate(2200)}
          >
            2.20
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 2400}
            onClick={() => props.flashRate(2400)}
          >
            2.40
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 2600}
            onClick={() => props.flashRate(2600)}
          >
            2.60
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 2800}
            onClick={() => props.flashRate(2800)}
          >
            2.80
          </Dropdown.Item>
          <Dropdown.Item
            active={props.flashBool === 3000}
            onClick={() => props.flashRate(3000)}
          >
            3.00
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Number
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            active={props.number === 2}
            onClick={() => {
              props.changeNumber(2);
              props.changeArray(handleWordListChange(2));
            }}
          >
            2 Words
          </Dropdown.Item>
          <Dropdown.Item
            active={props.number === 3}
            onClick={() => {
              props.changeNumber(3);
              props.changeArray(handleWordListChange(3));
            }}
          >
            3 Words
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Pattern Highlight
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            active={props.mode === "highlight"}
            onClick={() => props.changeMode("highlight")}
          >
            Highlight the vowel
          </Dropdown.Item>
          <Dropdown.Item
            active={props.mode === "off"}
            onClick={() => props.changeMode("off")}
          >
            Off
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            active={props.wordList === "small"}
            onClick={() => {
              props.changeList("small");
              props.changeArray(handleWordListChange("small"));
            }}
          >
            Small words
          </Dropdown.Item>
          <Dropdown.Item
            active={props.wordList === "Blends"}
            onClick={() => {
              props.changeList("Blends");
              props.changeArray(handleWordListChange("Blends"));
            }}
          >
            Blends
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
