import { useState } from "react";

export default function DescribingPicture(props) {
  const [image, setImage] = useState();
  const [started, setStarted] = useState(false);

  const [chosen, setChosen] = useState([]);

  function selectImage() {
    let random = Math.floor(Math.random() * 29) + 1;
    if (chosen.length === 29) {
      let start = [];
      start.push(random);
      setChosen(start);
      return random;
    }
    let listCopy = chosen;
    if (!chosen.includes(random)) {
      listCopy.push(random);
      setChosen(listCopy);
      return random;
    }
    return selectImage();
  }

  function nextImage() {
    let num = selectImage();
    setImage(num);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {props.open && started && (
        <img
          style={{ flex: "9", height: "90%", margin: "auto" }}
          src={
            "Visualization/DescribingPictures/" + JSON.stringify(image) + ".jpg"
          }
        />
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="button_section activityActionButton"
          onClick={() => {
            if (props.open) {
              nextImage();
              setStarted(true);
            } else {
              props.openActivity(true);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
