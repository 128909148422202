export const noActivity = [
  "/Coding/AngelsintheSnow.pdf",
  "/Visualization/Feel&Match.pdf",
  "/EyeMovements/LookClosely.pdf",
  "/EyeMovements/InfinityTracking.pdf",
  "/BuildingBinocularity/SuperVision.pdf",
  "/BuildingBinocularity/BeanBagSwing.pdf",
  "/BuildingBinocularity/TorchTag.pdf",
  "/BuildingBinocularity/BallonString.pdf",
  "/BuildingBinocularity/WalkthePlank.pdf",
  "/BuildingBinocularity/MirrorWorld.pdf",
  "/BuildingBinocularity/BeanBagToss.pdf",
  "/BuildingBinocularity/BeadsonaString.pdf",
  // "/BuildingBinocularity/X-RayVision.pdf",
  "/VisuallyDirectedFineMotor/MatchingColours.pdf",
  // "/VisuallyDirectedFineMotor/AnimalShapes.pdf",
  // "/VisuallyDirectedFineMotor/ShadowPuppets.pdf",
  "/VisuallyDirectedFineMotor/JointheCircus.pdf",
  // "/VisuallyDirectedFineMotor/Origami.pdf",
];
