import Button from "react-bootstrap/Button";
import React from "react";
import { random } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

class Level5_MemoryDotsPeg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numDots: 2,
      level: 1,
      gridSize: 5,
      level1Indexes: [1, 3, 5, 7, 9],
      level2Indexes: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      correctIndexes: [0, 0, 0, 0, 0, 0],
      fillList: ["greenfill", "yellowfill", "redfill", "bluefill"],
      clickNum: 0,
      clickedDots: [0, 0, 0, 0, 0, 0],
      checked: true,
      started: false,
      checkEnable: true,
      startEnable: false,
      circleVis: "hidden",
      repeat: true,
      next: true,
      flashTime: 1000,
      sequence: false,
      selectedFills: [],
    };
    this.startActivity = this.startActivity.bind(this);
    this.checkActivity = this.checkActivity.bind(this);
    this.setLevel = this.setLevel.bind(this);
    this.setNumDots = this.setNumDots.bind(this);
    this.clickDot = this.clickDot.bind(this);
    this.iterateCorrectDots = this.iterateCorrectDots.bind(this);
  }

  componentDidMount() {
    console.log(React.version);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.level != this.state.level) {
      this.resetGrid();
    }
    if (prevState.sequence != this.state.sequence) {
      this.setState({ startEnable: false });
      this.setState({ repeat: true });
      this.setState({ next: true });
      this.resetGrid();
    }
    if (prevState.numDots != this.state.numDots) {
      this.resetGrid();
    }
    if (prevState.flashTime != this.state.flashTime) {
      this.setState({ startEnable: false });
      this.setState({ repeat: true });
      this.setState({ next: true });
      this.resetGrid();
    }
  }

  resetGrid(gridSize) {
    for (let i = 0; i < 10; i++) {
      this.setState({ ["number" + i]: "" });
      this.setState({ ["circle" + i]: "silverfill" });
    }
    this.setState({ checkEnable: true });
  }

  startActivity() {
    this.setState({ started: true });
    this.setState({ checked: true });
    this.setState({ startEnable: true });
    this.setState({ clickNum: 1 });

    let indexList = [];
    let correctIndexList = this.state.correctIndexes;
    if (this.state.level == 1) {
      indexList = this.state.level1Indexes;
    } else {
      indexList = this.state.level2Indexes;
    }

    //set all numbers in dots to null and all dots to silver
    this.resetGrid();
    console.log("just reset grid");

    //select x=numDots dots randomly and play each for x seconds
    for (let i = 0; i < this.state.numDots; i++) {
      let cont = true;
      while (cont) {
        let randomIndex = Math.floor(Math.random() * indexList.length);

        let currDot = indexList[randomIndex];

        if (!correctIndexList.includes(currDot)) {
          cont = false;
          console.log(currDot);
          correctIndexList[i] = currDot;
        }
      }
    }

    this.iterateCorrectDots(correctIndexList, 0, this.state.numDots, true);

    this.setState({ correctIndexes: correctIndexList });
    console.log(correctIndexList);
  }

  seenTwice(fill) {
    let count = 0;
    for (let i = 0; i < this.state.numDots; i++) {
      if (this.state.selectedFills[i] === fill) {
        count++;
      }
    }
    if (count === 2) {
      return true;
    }
    return false;
  }

  pickRandomColor(index) {
    let randIndex = Math.floor(Math.random() * 4);
    let fill = this.state.fillList[randIndex];
    if (!this.seenTwice(fill)) {
      this.state.selectedFills[index] = fill;
    } else {
      return this.pickRandomColor(index);
    }
    return fill;
  }

  iterateCorrectDots(correctIndexes, i, numDots, random) {
    console.log(correctIndexes, "correct indexes");
    console.log(i, "this is i");
    console.log(numDots, "this is the numDots");
    if (!this.state.sequence) {
      console.log("all at once", correctIndexes);
      for (let i = 0; i < numDots; i++) {
        if (random) {
          let fill = this.pickRandomColor(i);
          this.setState({ ["circle" + correctIndexes[i]]: fill });
        } else {
          this.setState({
            ["circle" + correctIndexes[i]]: this.state.selectedFills[i],
          });
        }
      }
      if (this.state.flashTime != 0) {
        setTimeout(() => {
          for (let i = 0; i < numDots; i++) {
            this.setState({ ["circle" + correctIndexes[i]]: "silverfill" });
          }
          this.setState({ started: false });
          this.setState({ checked: false });
          this.setState({ checkEnable: false });
          this.setState({ startEnable: true });
          this.setState({ repeat: false });
          this.setState({ next: false });
        }, this.state.flashTime);
      } else {
        this.setState({ next: false });
      }
    } else {
      if (random) {
        let fill = this.pickRandomColor(i);
        this.setState({ ["circle" + correctIndexes[i]]: fill });
      } else {
        this.setState({
          ["circle" + correctIndexes[i]]: this.state.selectedFills[i],
        });
      }
      // this.setState({ ["circle" + correctIndexes[i]]: "greenfill" });
      setTimeout(() => {
        this.setState({ ["circle" + correctIndexes[i]]: "silverfill" });
        if (i < numDots) {
          this.iterateCorrectDots(correctIndexes, i + 1, numDots, random);
        } else {
          this.setState({ started: false });
          this.setState({ checked: false });
          this.setState({ checkEnable: false });
          this.setState({ startEnable: true });
          this.setState({ repeat: false });
        }
      }, this.state.flashTime);
    }
  }

  checkActivity() {
    //just turn circles red or green depending on if the order and placement was correct
    this.setState({ startEnable: true });
    for (let i = 0; i < this.state.numDots; i++) {
      console.log("correctIndex[i] " + this.state.correctIndexes[i]);
      console.log("clickedDots[i] " + this.state.clickedDots[i]);
      this.setState({
        ["circle" + this.state.correctIndexes[i]]: this.state.selectedFills[i],
      });
      if (this.state.sequence) {
        this.setState({ ["number" + this.state.correctIndexes[i]]: i + 1 })
      }
    }
    this.setState({ checkEnable: true });
    this.setState({ checked: true });
    this.setState({ clickedDots: [0, 0, 0, 0, 0, 0] });

    this.setState({ next: false });
  }

  clickDot(dotIndex) {
    if (!this.state.started) {
      if (
        this.state.clickNum <= this.state.numDots &&
        !this.state.clickedDots.includes(dotIndex)
      ) {
        this.setState({ ["number" + dotIndex]: this.state.clickNum });
        let clickedDots = this.state.clickedDots;
        clickedDots[this.state.clickNum - 1] = dotIndex;
        console.log(
          "clicked " +
            dotIndex +
            " as #" +
            (this.state.clickNum - 1) +
            " click",
        );

        this.state.clickNum = this.state.clickNum + 1;
        this.state.clickedDots = clickedDots;
        console.log("clicked dots " + this.state.clickedDots);
      }
      if (this.state.clickNum > this.state.numDots) {
        this.setState({ checkEnable: false });
      }
    }
  }

  setLevel(e) {
    this.setState({ level: e });
    if (e == 1) {
      this.setState({ gridSize: 5 });
    } else {
      this.setState({ gridSize: 9 });
    }
    this.resetGrid();
    this.setState({ startEnable: false });
    this.setState({ next: true });
    this.setState({ repeat: true });
    this.setState({ correctIndexes: [0, 0, 0, 0, 0, 0] });
  }

  setNumDots(e) {
    this.setState({ numDots: e });
    this.resetGrid(this.state.gridSize);
    this.setState({ startEnable: false });
    this.setState({ next: true });
    this.setState({ repeat: true });
    this.setState({ correctIndexes: [0, 0, 0, 0, 0, 0] });
  }

  setSequence(e) {
    this.setState({ sequence: e });
  }

  repeat() {
    this.resetGrid();
    this.setState({ clickNum: 1 });
    this.setState({ repeat: true });
    setTimeout(() => {
      this.iterateCorrectDots(
        this.state.correctIndexes,
        0,
        this.state.numDots,
        false,
      );
    }, 250);
    if (!this.state.sequence) {
      setTimeout(() => {
        this.setState({ next: false });
      }, 250 + this.state.flashTime);
    }
  }

  render() {
    return (
      <div className="activity_box">
        <div className="activitySettingsButtonsContainer">
        <div style={{ display: "flex ", gap: "1rem" }}>
          <Dropdown>
            <Dropdown.Toggle style={toggleStyle} variant="success" id="dropdown-basic">
              Flash Rate
            </Dropdown.Toggle>
            <Dropdown.Menu style={menuStyle}>
              {!this.state.sequence && (
                <Dropdown.Item
                  onClick={() => {
                    this.setState({ flashTime: 0 });
                  }}
                  active={this.state.flashTime === 0}
                >
                  No Flash
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 500 });
                }}
                active={this.state.flashTime === 500}
              >
                0.5
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 600 });
                }}
                active={this.state.flashTime === 600}
              >
                0.6
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 700 });
                }}
                active={this.state.flashTime === 700}
              >
                0.7
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 800 });
                }}
                active={this.state.flashTime === 800}
              >
                0.8
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 900 });
                }}
                active={this.state.flashTime === 900}
              >
                0.9
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 1000 });
                }}
                active={this.state.flashTime === 1000}
              >
                1.0
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 1200 });
                }}
                active={this.state.flashTime === 1200}
              >
                1.2
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 1400 });
                }}
                active={this.state.flashTime === 1400}
              >
                1.4
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 1600 });
                }}
                active={this.state.flashTime === 1600}
              >
                1.6
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 1800 });
                }}
                active={this.state.flashTime === 1800}
              >
                1.8
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setState({ flashTime: 2000 });
                }}
                active={this.state.flashTime === 2000}
              >
                2.0
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle style={toggleStyle} variant="success" id="dropdown-basic">
              Number
            </Dropdown.Toggle>
            <Dropdown.Menu style={menuStyle}>
              <Dropdown.Item
                onClick={() => this.setNumDots(2)}
                active={this.state.numDots == 2 ? true : false}
              >
                2 Dots
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.setNumDots(3)}
                active={this.state.numDots == 3 ? true : false}
              >
                3 Dots
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.setNumDots(4)}
                active={this.state.numDots == 4 ? true : false}
              >
                4 Dots
              </Dropdown.Item>
              {this.state.level === 2 && (
                <div>
                  <Dropdown.Item
                    onClick={() => this.setNumDots(5)}
                    active={this.state.numDots == 5 ? true : false}
                  >
                    5 Dots
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => this.setNumDots(6)}
                    active={this.state.numDots == 6 ? true : false}
                  >
                    6 Dots
                  </Dropdown.Item>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle style={toggleStyle} variant="success" id="dropdown-basic">
              Display
            </Dropdown.Toggle>
            <Dropdown.Menu style={menuStyle}>
              <Dropdown.Item
                onClick={() => this.setSequence(false)}
                active={!this.state.sequence}
              >
                All at once
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setSequence(true);
                  if (this.state.flashTime === 0)
                    this.setState({ flashTime: 1000 });
                }}
                active={this.state.sequence}
              >
                Sequence
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle style={toggleStyle} variant="success" id="dropdown-basic">
              Progression
            </Dropdown.Toggle>
            <Dropdown.Menu style={menuStyle}>
              <Dropdown.Item
                onClick={() => {
                  this.setLevel(1);
                  this.setState({ circleVis: "hidden" });
                }}
                active={this.state.level == 1 ? true : false}
              >
                Level 1: Small Grid
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  this.setLevel(2);
                  this.setState({ circleVis: "visible" });
                }}
                active={this.state.level == 2 ? true : false}
              >
                Level 2: Large Grid
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        </div>
        {
          this.props.open &&
        <div className="container">
          <div id="columns">
            <div className="circRow">
              <div
                className="circle"
                id={this.state.circle1}
              >
                {this.state.number1}
              </div>
              <div
                className="circle"
                id={this.state.circle2}
                style={{ visibility: this.state.circleVis }}
              >
                {this.state.number2}
              </div>
              <div
                className="circle"
                id={this.state.circle3}
              >
                {this.state.number3}
              </div>
            </div>
            <div className="circRow">
              <div
                className="circle"
                id={this.state.circle4}
                style={{ visibility: this.state.circleVis }}
              >
                {this.state.number4}
              </div>
              <div
                className="circle"
                id={this.state.circle5}
              >
                {this.state.number5}
              </div>
              <div
                className="circle"
                id={this.state.circle6}
                style={{ visibility: this.state.circleVis }}
              >
                {this.state.number6}
              </div>
            </div>
            <div className="circRow">
              <div
                className="circle"
                id={this.state.circle7}
              >
                {this.state.number7}
              </div>
              <div
                className="circle"
                id={this.state.circle8}
                style={{ visibility: this.state.circleVis }}
              >
                {this.state.number8}
              </div>
              <div
                className="circle"
                id={this.state.circle9}
              >
                {this.state.number9}
              </div>
            </div>
          </div>
        </div>
        }
        <div className="button_section activityActionButtonsContainer">
          <button
            className="activityActionButton"
            disabled={this.state.startEnable}
            onClick={() => {
              if (this.props.open) {
              this.startActivity() 
            } else {
                this.props.openActivity(true);
            }}}
          >
            Start
          </button>
          <button
            className="activityActionButton"
            disabled={
              this.state.checkEnable ||
              this.state.flashTime === 0 ||
              !this.state.sequence
            }
            onClick={() => {
              if (this.props.open) {

              this.checkActivity()} else {
                this.props.openActivity(true);
              }}}
          >
            Check
          </button>
          <button
            className="activityActionButton"
            onClick={() => {
              if (this.props.open) {
              this.repeat()
            } else {
                this.props.openActivity(true);
              }}}
            disabled={this.state.repeat || this.state.flashTime === 0}
          >
            Repeat
          </button>
          <button
            className="activityActionButton"
            onClick={() => {
              if (this.props.open) {
              this.resetGrid();
              this.setState({ startEnable: false });
              this.setState({ next: true });
              this.setState({ repeat: true });
              this.setState({ correctIndexes: [0, 0, 0, 0, 0, 0] });
              for (let i = 0; i < this.state.numDots; i++) {
                this.state.selectedFills[i] = "";
              }
              } else {
                this.props.openActivity(true);
              }
            }}
            disabled={this.state.next}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Level5_MemoryDotsPeg;
