import { useNavigate } from "react-router-dom";

import { signOut } from "firebase/auth";
import { auth } from "../../base";

import { AiOutlineLeft as BackIcon } from "react-icons/ai";

import { spellOutDate } from "../../utils/formatDate";

function LicenseInformation(props) {
  return (
    <div className="adminLicensesLabels">
      <p>{props.license}</p>
      <p>{props.activated ? "Yes" : "No"}</p>
      <p>{props.providerPractice}</p>
      <p>{props.patientEmail}</p>
      <p>
        {props.experationDate &&
          spellOutDate(new Date(props.experationDate.seconds * 1000))}
      </p>
    </div>
  );
}

export function AdminAccount(props) {
  const navigate = useNavigate();

  return (
    <>
      <div className="accountHeader">
        <h1 style={{ fontFamily: "NexaBold", fontSize: "22px" }}>My Account</h1>
        <div
          onClick={async () => {
            await signOut(auth);
            navigate("/login");
          }}
          id="backButtonContainer"
        >
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Sign Out</p>
        </div>
      </div>
      <div className="licensesContainer">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          License Information
        </p>
        <div className="adminLicensesLabels">
          <p>License</p>
          <p>Activated</p>
          <p>Provider Practice</p>
          <p>Patient Email</p>
          <p>Experation</p>
        </div>
        {props.licenseData
          .sort((a, b) => {
            if (a.used === b.used) {
              return 0;
            }
            if (a.used) {
              return -1;
            }
            return 1;
          })
          .map((license) => (
            <LicenseInformation
              key={license.license}
              license={license.license}
              activated={license.used}
              experationDate={license.endDate}
              patientEmail={license.patientEmail}
              providerPractice={license.providerPractice}
            />
          ))}
      </div>
      <div className="emailSubscriptionsContainer">
        <p style={{ fontSize: "18px", fontWeight: "600" }}>
          Email Subscriptions
        </p>
        <div className="emailSubscriptions">
          <p>Email</p>
          <p>Subscribed</p>
        </div>
        {props.userData.subscribers.map((subscriber) => (
          <div className="emailSubscriptions">
            <p>{subscriber.email}</p>
            <p>{subscriber.subscribed ? "Yes" : "No"}</p>
          </div>
        ))}
      </div>
    </>
  );
}
