import Dropdown from "react-bootstrap/Dropdown";
import {
  SingleA,
  SingleB,
  SingleC,
  SingleD,
  DoubleA,
  DoubleB,
  DoubleC,
  DoubleD,
} from "./LetterChartWords";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function LetterChartButtons(props) {
  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Flash Rate
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          {/* <Dropdown.Item
            onClick={() => props.changeSound(!props.soundBool)}
            active={!props.soundBool}
          >
            Silent
          </Dropdown.Item> */}
          <Dropdown.Item
            onClick={() => {
              props.flashRate(500);
            }}
            active={props.flashBool === 500}
          >
            0.50
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(600);
            }}
            active={props.flashBool === 600}
          >
            0.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(700);
            }}
            active={props.flashBool === 700}
          >
            0.70
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(800);
            }}
            active={props.flashBool === 800}
          >
            0.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(900);
            }}
            active={props.flashBool === 900}
          >
            0.90
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(1000);
            }}
            active={props.flashBool === 1000}
          >
            1.00
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(1200);
            }}
            active={props.flashBool === 1200}
          >
            1.20
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(1400);
            }}
            active={props.flashBool === 1400}
          >
            1.40
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(1600);
            }}
            active={props.flashBool === 1600}
          >
            1.60
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(1800);
            }}
            active={props.flashBool === 1800}
          >
            1.80
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.flashRate(2000);
            }}
            active={props.flashBool === 2000}
          >
            2.00
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Letter Spacing
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeWordList(DoubleA);
              props.changeWidth("3em");
            }}
            active={props.currWidth === "3em"}
          >
            Level 1: Double
          </Dropdown.Item>
          {props.rowBool !== 10 && (
            <Dropdown.Item
              onClick={() => {
                props.changeSpacing("32px");
                props.changeWordList(SingleA);
                props.changeWidth("auto");
              }}
              active={props.currWidth === "auto"}
            >
              Level 2: Single
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Highlight Display
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeFlash("FirstLast")}
            active={props.triggerBool === "FirstLast"}
          >
            First Letter of Each Word
          </Dropdown.Item>
          {props.rowBool !== 3 && (
            <Dropdown.Item
              onClick={() => props.changeFlash("Second")}
              active={props.triggerBool === "Second"}
            >
              Off
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => props.changeFlash("Off")}
            active={props.triggerBool === "Off"}
          >
            Off
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        {props.currWidth === "3em" && (
          <Dropdown.Menu style={menuStyle}>
            <Dropdown.Item
              onClick={() => props.changeWordList(DoubleA)}
              active={props.wordList === DoubleA}
            >
              Double: A
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(DoubleB)}
              active={props.wordList === DoubleB}
            >
              Double: B
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(DoubleC)}
              active={props.wordList === DoubleC}
            >
              Double: C
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(DoubleD)}
              active={props.wordList === DoubleD}
            >
              Double: D
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
        {props.currWidth === "auto" && (
          <Dropdown.Menu style={menuStyle}>
            <Dropdown.Item
              onClick={() => props.changeWordList(SingleA)}
              active={props.wordList === SingleA}
            >
              Single: A
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(SingleB)}
              active={props.wordList === SingleB}
            >
              Single: B
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(SingleC)}
              active={props.wordList === SingleC}
            >
              Single: C
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => props.changeWordList(SingleD)}
              active={props.wordList === SingleD}
            >
              Single: D
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
      {/* <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Chart
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => props.changeMode("lowercase")}
            active={props.modeBool === "lowercase"}
          >
            Lowercase
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeMode("uppercase")}
            active={props.modeBool === "uppercase"}
          >
            Uppercase
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      {/* <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Character Size
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => props.changeSize("24pt")}
            active={props.sizeBool === "24pt"}
          >
            Small (24 point)
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeSize("30pt")}
            active={props.sizeBool === "30pt"}
          >
            Medium (30 point)
          </Dropdown.Item>
          {(props.rowBool === 3 ||
            props.rowBool === 5 ||
            props.rowBool === 4) && (
            <div>
              <Dropdown.Item
                onClick={() => props.changeSize("36pt")}
                active={props.sizeBool === "36pt"}
              >
                Normal (36 point)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => props.changeSize("42pt")}
                active={props.sizeBool === "42pt"}
              >
                Large (42 point)
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown> */}
    </div>
  );
}
