export const SmallWordsDouble = [
  "cat nap",
  "fat hat",
  "bad bat",
  "mad man",
  "tap mat",
  "dad sat",
  "rat bag",
  "had jam",
  "sad man",
  "ham bag",
  "bad cap",
  "mad lad",
  "wet den",
  "get wet",
  "let men",
  "pet vet",
  "get net",
  "ten men",
  "hen pen",
  "pet pen",
  "bed net",
  "wet leg",
  "men beg",
  "fed pet",
  "dig in",
  "hit his",
  "rip tin",
  "his pig",
  "nip fig",
  "pig pit",
  "kid bit",
  "his lip",
  "his pin",
  "big kid",
  "in lid",
  "hid kid",
  "got dog",
  "not fog",
  "hot mop",
  "hot dog",
  "on top",
  "not hot",
  "on pot",
  "bog rot",
  "dog cot",
  "hog bog",
  "hop on",
  "top dog",
  "cut gum",
  "bug run",
  "fun sun",
  "mum hum",
  "dug up",
  "mud hut",
  "fun run",
  "bug tub",
  "nut tub",
  "fun mum",
  "sun bug",
  "cut up",
  "bad dog",
  "big bag",
  "get gum",
  "not mad",
  "pat hog",
  "pet bug",
  "men sit",
  "hot pan",
  "cut leg",
  "hot sun",
  "wet tub",
  "hid gem",
  "hot tub",
  "tin van",
  "fig jam",
  "big hat",
  "fun cap",
  "jam pot",
  "let him",
  "sit on",
  "had nap",
  "wet cat",
  "wet dog",
  "get rid",
  "had fin",
  "mad kid",
  "had fun",
  "pet cat",
  "hot sap",
  "his pen",
  "wet mud",
  "leg hem",
  "big peg",
  "tub mat",
  "dog wag",
  "his mum",
  "his dad",
  "pat dog",
  "big mug",
  "fun kid",
  "hot rat",
  "pin on",
  "dig ten",
  "fat hen",
  "big bat",
];

export const SmallWordsTriple = [
  "His cat sat",
  "fed his pet",
  "fun sun tan",
  "on can lids",
  "mad man raps",
  "dip wet rag",
  "big dogs beg",
  "wet hogs hop",
  "fed fat rat",
  "mum gets gum",
  "let hens run",
  "met sad lad",
  "kids get cups",
  "dig up gems",
  "wet mops sag",
  "hid his jam",
  "big sun set",
  "hot dog buns",
  "ten hot pots",
  "dots on maps",
  "tip hot mud",
  "his pig sat",
  "hens get wet",
  "hop on pop",
  "not his nan",
  "his mum nods",
  "hot figs rot",
  "hum in bed",
  "run in mud",
  "his pet cat",
  "men on mats",
  "tap on pegs",
  "ten hot bugs",
  "dad and mum",
  "cut his ham",
  "got bad cut",
  "dots on pads",
  "kids get pets",
  "hats on cats",
  "caps on pigs",
  "bugs in hats",
];

export const BlendTriple = [
  "clap and wish",
  "chat then snack",
  "drag from track",
  "hands grab grips",
  "test this lamp",
  "snap that stick",
  "trap lost ducks",
  "black fish swam",
  "pick that lock",
  "catch this tram",
  "smash that drum",
  "jump and catch",
  "catch then kick",
  "bend this clip",
  "from this hill",
  "held stick still",
  "lend that pram",
  "fill that crack",
  "lush plants sell",
  "will spend cash",
  "send this stamp",
  "spill that muck",
  "grill plump fish",
  "ants sting chicks",
  "split and bend",
  "mist and frost",
  "flags must drop",
  "glad frogs jump",
  "itch that rash",
  "skid then stop",
  "drop that dish",
  "damp tents flap",
  "slam vent shut",
  "less black sand",
  "traps will snap",
  "thin black socks",
  "spots will blend",
  "mends best dress",
  "will stack bricks",
  "hands flick slugs",
  "with long bills",
  "picks scab off",
  "thin frogs sing",
  "swift ducks flap",
  "crusts will snap",
];

export const BlendsDouble = [
  "cash stash",
  "grand slam",
  "catch latch",
  "flag flap",
  "pram stash",
  "back pack",
  "black sack",
  "bland snack",
  "grand scam",
  "sand rash",
  "crab snap",
  "and scram",
  "grand band",
  "that drag",
  "crab swam",
  "plant land",
  "swamp plant",
  "and chat",
  "drag and",
  "crack lamp",
  "that track",
  "pram ramp",
  "glad band",
  "damp hand",
  "less bend",
  "spell well",
  "sled sped",
  "mend tent",
  "fetch sled",
  "yell less",
  "end rest",
  "blend less",
  "held fleck",
  "bless mess",
  "fret then",
  "best nest",
  "rest pest",
  "then fetch",
  "mend tent",
  "swim fish",
  "swift kick",
  "chip clip",
  "itch stitch",
  "thin mist",
  "chill grin",
  "still slip",
  "pick flick",
  "itch chin",
  "drip spit",
  "swift skid",
  "grip stick",
  "sting chick",
  "witch kick",
  "trim stitch",
  "stop clock",
  "lost flock",
  "frog slosh",
  "drop blob",
  "drop sock",
  "frost stop",
  "off dock",
  "stop crop",
  "long lost",
  "stop from",
  "knock off",
  "lost spot",
  "dump truck",
  "must jump",
  "muck ruck",
  "shut pump",
  "must bump",
  "plump plum",
  "lush duck",
  "stuck slug",
  "flush much",
  "stuck truck",
  "duck cluck",
  "must punt",
  "crush mush",
  "drum rust",
  "ant sting",
  "swim less",
  "still knock",
  "crack lock",
  "damp tent",
  "itch spot",
  "stack brick",
  "black sand",
  "black clam",
  "smell dump",
  "rest stop",
  "pump spill",
  "much mist",
  "sick frog",
  "tuck crust",
  "grab chin",
  "grand mess",
  "flag drop",
  "spit slug",
  "click lock",
  "spot dent",
  "block smell",
  "black pram",
  "drop egg",
  "clip wing",
  "mend vent",
  "pick scab",
  "stop skid",
  "flat nest",
  "bend back",
  "went back",
  "pick lock",
  "lend drum",
  "hand slip",
  "spend less",
  "stuck clam",
  "clap hand",
  "camp tent",
  "spill mess",
  "grab sock",
  "sled frost",
  "hot vent",
  "send mum",
  "wet mess",
  "lost fish",
  "sick kid",
  "spell well",
  "big ant",
  "lost dog",
  "still cat",
  "wet smell",
  "big tent",
  "swim well",
  "big clap",
  "bad luck",
  "smell bad",
  "big nest",
  "stuck on",
  "send off",
  "bad egg",
  "big pest",
  "his plant",
];

export const LongReal3 = [
  "Light",
  "bright",
  "sight",
  "right",
  "slight",
  "fight",
  "high",
  "sigh",
  "might",
  "tight",
  "blight",
  "fright",
  "delight",
  "night",
  "Bay",
  "day",
  "cay",
  "hay",
  "jay",
  "lay",
  "clay",
  "may",
  "pay",
  "say",
  "way",
  "stay",
  "slay",
  "play",
  "sway",
  "spray",
  "gray",
  "pray",
  "ray",
  "tray",
  "stray",
  "By",
  "cry",
  "fly",
  "sly",
  "try",
  "ply",
  "sky",
  "fry",
  "shy",
  "dry",
  "my",
  "why",
];

export const realRWords = [
  "Bar",
  "car",
  "far",
  "jar",
  "mar",
  "par",
  "tar",
  "bark",
  "dark",
  "lark",
  "mark",
  "park",
  "barn",
  "card",
  "lard",
  "hard",
  "stark",
  "star",
  "spark",
  "darn",
  "tarn",
  "art",
  "ark",
  "arm",
  "charm",
  "chart",
  "shark",
  "shard",
  "sharp",
  "harp",
  "barb",
  "carp",
  "farm",
  "harm",
  "scar",
  "garb",
  "cart",
  "part",
  "dart",
  "tart",
  "start",
  "spar",
  "Fern",
  "stern",
  "tern",
  "her",
  "herb",
  "herd",
  "perch",
  "jerk",
  "perk",
  "berth",
  "Bird",
  "shirt",
  "skirt",
  "fir",
  "stir",
  "third",
  "flirt",
  "sir",
  "dirt",
  "stir",
  "birth",
  "Fork",
  "cork",
  "pork",
  "ford",
  "cord",
  "lord",
  "scorch",
  "for",
  "nor",
  "porch",
  "torch",
  "horn",
  "corn",
  "fort",
  "sport",
  "sort",
  "port",
  "storm",
  "scorn",
  "snort",
  "short",
  "north",
  "form",
  "fur",
  "purr",
  "purse",
  "burr",
  "burn",
  "burnt",
  "spur",
  "spurt",
  "church",
  "lurk",
  "slur",
  "hurt",
  "blur",
  "nurse",
  "curse",
  "surf",
];

export const other1 = [
  "Crawl",
  "call",
  "wall",
  "fall",
  "hall",
  "mall",
  "tall",
  "stall",
  "small",
  "shawl",
  "drawl",
  "raw",
  "draw",
  "paw",
  "law",
  "saw",
  "jaw",
  "hawk",
  "talk",
  "walk",
  "dawn",
  "fawn",
  "sauce",
  "pause",
  "cause",
  "daughter",
  "laundry",
  "gauze",
  "caution",
  "chalk",
  "haunt",
  "lawn",
  "launch",
  "staunch",
  "naughty",
  "brawl",
  "taut",
  "caught",
  "all",
  "bald",
  "ball",
  "sauna",
  "fauna",
  "haul",
  "maul",
  "Paul",
  "squawk",
  "fraud",
  "flaw",
  "straw",
  "claw",
  "thaw",
  "dawn",
  "yawn",
  "pawn",
  "shawl",
  "scrawl",
  "trawl",
  "drawl",
];

export const other2 = [
  "Boy",
  "toy",
  "joy",
  "coin",
  "join",
  "loin",
  "oil",
  "foil",
  "soil",
  "toil",
  "coil",
  "boil",
  "hoist",
  "poison",
  "noise",
  "choice",
  "voice",
  "point",
  "joint",
  "spoil",
  "moist",
  "groin",
  "avoid",
  "moistue",
  "noisy",
  "appoint",
  "ploy",
  "employ",
  "destroy",
  "enjoy",
  "deploy",
  "rejoice",
  "toilet",
  "turmoil",
  "decoy",
  "oyster",
  "royal",
  "loyal",
  "soy",
  "voyage",
  "ahoy",
];

export const other3 = [
  "Cool",
  "fool",
  "stool",
  "pool",
  "root",
  "boot",
  "foot",
  "hoot",
  "loot",
  "soot",
  "cook",
  "book",
  "look",
  "nook",
  "rook",
  "took",
  "soon",
  "spoon",
  "snoop",
  "troop",
  "snooker",
  "shoot",
  "scoot",
  "scooter",
  "wood",
  "good",
  "hood",
  "chook",
  "boom",
  "loom",
  "doom",
  "room",
  "hook",
  "shook",
  "boost",
  "tooth",
  "roost",
  "mood",
  "food",
  "smooth",
  "goose",
  "moose",
  "booth",
  "wool",
  "tool",
  "poodle",
  "loop",
  "scoop",
  "stood",
  "noon",
  "loose",
  "snooze",
  "moon ",
  "roof",
  "hoof",
  "sooth",
  "school",
  "choose",
  "groove",
  "tycoon",
  "spook",
  "proof",
  "zoom",
  "brook",
];

export const other4 = [
  "Grow",
  "slow",
  "blow",
  "flow",
  "flower",
  "tower",
  "power",
  "lower",
  "mower",
  "mow",
  "low",
  "sow",
  "bow",
  "cow",
  "how",
  "now",
  "row",
  "tow",
  "vow",
  "wow",
  "show",
  "glow",
  "know",
  "own ",
  "sown",
  "grown",
  "frown",
  "drown",
  "clown",
  "rowdy",
  "crowd",
  "crow",
  "shower",
  "stow",
  "down",
  "owe ",
  "owl",
  "town",
  "brown",
  "blown",
  "towel",
  "snow",
  "bowl",
  "elbow",
  "brow",
  "fowl",
];

export const other5 = [
  "Air",
  "chair",
  "fair",
  "hair",
  "pair",
  "beer",
  "steer",
  "clear",
  "fear",
  "rear",
  "deer",
  "jeer",
  "mere",
  "near",
  "peer",
  "tear",
  "sear",
  "rear",
  "veer",
  "clear",
  "bear",
  "bare",
  "stare",
  "fare",
  "dare",
  "care",
  "pare",
  "pear",
  "wear",
  "rare",
  "here",
  "gear",
  "year",
  "lair",
  "ear",
  "mare",
  "spare",
  "dear",
  "ware",
  "repair",
  "dairy",
  "fairy",
  "flair",
  "stair",
  "hairy",
  "affair",
  "shear",
  "spear",
  "hare",
  "aware",
  "snare",
  "glare",
  "flare",
];

export const breaker1 = [
  "Most",
  "host",
  "post",
  "worse",
  "worst",
  "war",
  "warn",
  "wart",
  "whose",
  "lose",
  "blind",
  "kind",
  "find",
  "bind",
  "mind",
  "yacht",
  "mother",
  "father",
  "brother",
  "other",
  "smother",
  "pass",
  "class",
  "fast",
  "cast",
  "blast",
  "grass",
  "flask",
  "last",
  "past",
  "vast",
  "ocean",
  "woman",
  "house",
  "couch",
  "pouch",
  "world",
  "warm",
  "sugar",
  "their",
  "there",
  "wild",
  "child",
  "mild",
  "many",
  "after",
  "blood",
  "flood",
  "ton",
  "son",
  "heavy",
  "they",
  "tough",
  "rough",
  "cough",
  "through",
  "thought",
  "though",
  "could",
  "would",
  "should",
  "once",
  "steak",
  "break",
  "laugh",
  "was",
  "water",
  "truth",
  "word",
  "you",
  "mouth",
  "south",
  "view",
  "month",
  "love",
  "dove",
  "above",
  "shove",
  "roll",
  "does",
  "full",
  "monkey",
  "ask",
  "cask",
  "task",
  "bread",
  "head",
  "thread",
  "spread",
  "today",
  "come",
  "some",
  "shoe",
  "friend",
  "found",
  "sound",
  "mound",
  "bound",
  "ground",
  "pound",
  "wound",
  "couch",
  "touch",
  "choir",
  "chemist",
  "honey",
  "money",
  "door",
  "floor",
  "move",
  "calm",
  "heart",
  "wash",
  "sew",
  "give",
  "do",
  "no",
  "so",
  "to",
  "four",
  "two",
  "biscuit",
  "onion",
  "what",
  "eye",
  "eight",
  "thief",
  "brief",
  "front",
  "ruin",
  "said",
  "juice",
  "gone",
  "pretty",
  "won",
  "guest",
  "guess",
  "guide",
  "bath",
  "path",
  "have",
  "done",
  "pull",
  "people",
  "weigh",
  "weight",
  "bush",
  "push",
  "cushion",
  "enough",
];

export const breaker2 = [
  "Include",
  "mountain",
  "fountain",
  "landscape",
  "normally",
  "formally",
  "evidence",
  "finish",
  "market",
  "demand",
  "captain",
  "station",
  "nation",
  "meeting",
  "investment",
  "transmision",
  "television",
  "different",
  "survivor",
  "management",
  "mistaken",
  "conservation",
  "painting",
  "helicopter",
  "equipment",
  "transform",
  "settlement",
  "regular",
  "include",
  "balance",
  "important",
  "sentenced",
  "consider",
  "moment",
  "produce",
  "landscape",
  "remain",
  "vibration",
  "prohibit",
  "adventure",
  "establish",
  "confidence",
  "national",
  "festival",
  "impression",
  "season",
  "insulation",
  "farming",
  "colony",
  "captain",
  "include",
  "convince",
  "problem",
  "resulting",
  "explain",
  "regular",
  "success",
  "criminal",
  "calculate",
  "community",
  "capital",
  "listening",
  "complain",
  "destruction",
  "company",
  "accommodate",
  "promise",
  "something",
  "printing",
  "eliminate",
  "transform",
  "following",
  "action",
  "identify",
  "prohibit",
  "animal",
  "jumped",
  "century",
  "settlement",
  "consider",
  "impression",
  "environment",
  "shaking",
  "closed",
  "isolated",
  "gather",
  "reporter",
  "recovery",
  "destroy",
  "airport",
  "generation",
  "paying",
  "relentless",
  "retire",
  "admit",
  "invent",
  "capital",
  "closed",
  "paying",
  "complain",
  "exactly",
  "season",
  "opinion",
  "invisible",
  "moment",
  "examination",
  "sentenced",
  "defeated",
  "already",
  "innocent",
  "tradition",
  "automatic",
  "responsible",
  "window",
  "insulation",
  "produce",
  "opened",
  "permanent",
  "entrance",
  "recovery",
  "complement",
  "credit",
  "suspect",
  "standard",
  "advantage",
  "wondering",
  "shaking",
  "passenger",
  "open",
  "closed",
  "certificate",
  "particular",
  "profitable",
  "represent",
  "foundation",
  "perform",
  "president",
  "beginning",
  "compass",
  "landscape",
  "umbrella",
  "happened",
  "beyond",
  "survivor",
  "children",
  "detail",
  "behind",
  "exactly",
  "precisely",
  "recovery",
  "confidence",
  "anywhere",
  "walking",
  "talking",
  "publish",
];
