import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth";

export const PlaySection = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const onLoginClick = () => {
    if (currentUser) {
      navigate("/activity");
    } else {
      navigate("/login");
    }
  };

  return (
    <div id="playSection">
      <div id="playSectionHeader">Play!</div>
      <div className="playBodyText">
        Piggyback is easy to use! Just click on "play".
      </div>
      <div className="playBodyText">
        Each activity has instructions on the left. To hide them, click on the
        expansion icon at the top right of the activity window.
        <br />
        Ready to level up? Open the right panel.
      </div>
      <button onClick={onLoginClick} id="playButton">
        {currentUser ? "Play" : "Login"}
      </button>
    </div>
  );
};
