import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function MagicGlassButtons(props) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Progression</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeImage("Caterpillar")}
            active={props.currImage === "Caterpillar"}
          >
            Level 1: Caterpillar Magic
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeImage("Seesaw")}
            active={props.currImage === "Seesaw"}
          >
            Level 2: See Saw Fun
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
