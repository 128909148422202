export const FAQSection = () => {
  return (
    <div id="faqSectionContainer">
      <div style={{ paddingBottom: "2rem" }} className="sectionHeader">
        FAQ's
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <div style={{ maxWidth: "500px" }}>
          <p className="boldHeader">
            How does playing Piggyback help improve my reading?
          </p>
          <p className="faqBodyText">
            Piggyback uses the latest neuroscience to help your brain build
            connections for coding language into reading and writing. Building
            your reading pathway one game at a time!{" "}
          </p>
        </div>
        <div style={{ maxWidth: "500px" }}>
          <p className="boldHeader">What is dyslexia?</p>
          <p className="faqBodyText">
            Humans are born to learn, and we believe everyone can learn to read.
            Dyslexia is a medical term used to describe delayed reading
            development. We disagree with this label, and instead view it as an
            unfinished process. Because it is not natural to read, developing
            specific brain pathways takes years. Dyslexia is just a word that
            means the reading pathway hasn’t fully developed yet.
          </p>
        </div>
      </div>
      <div>
        <div style={{ maxWidth: "500px" }}>
          <p className="boldHeader">How can Piggyback help me learn?</p>
          <p className="faqBodyText">
            Developing your visual system takes mindful practice. You will learn
            to process visual information with greater speed and understanding,
            as we help you through the journey ahead.
          </p>
        </div>
      </div>
    </div>
  );
};
