import OrigamiOptions from "./OrigamiOptions"
import { useEffect, useState } from "react";

export default function Level7_Origrami(props) {
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState(0);
  var [dirPath, setDirPath] = useState("/VisuallyDirectedFineMotor/Origami/");

  const [level, setLevel] = useState("Level1");
  const [display, setDisplay] = useState(1);
  const [next, setNext] = useState(false);
  const [check, setCheck] = useState(true);

  // const Level4 = [
  //   "Crocodile",
  //   "Dinosaur",
  //   "Wombat",
  //   "Pengunin",
  //   "Frilled Lizard",
  //   "Emu",
  //   "Dog",
  // ];
  // const Level1 = ["Alien", "Tugboat", "House", "Bird", "Volcano"];
  // const Level2 = ["Bat", "Butterfly", "Fish", "Platypus", "Koala"];
  // const Level3 = ["Pelican", "Kangaroo", "Sea Tutle", "Sugar Glider", "Dingo"];

  // function preLoadImages() {
  //   for (let i = 0; i < 4; i++) {
  //     if (i === 0) {
  //       loadLevel(i, Level1);
  //     }
  //     if (i === 1) {
  //       loadLevel(i, Level2);
  //     }
  //     if (i === 2) {
  //       loadLevel(i, Level3);
  //     }
  //     if (i === 3) {
  //       loadLevel(i, Level4);
  //     }
  //   }
  // }

  // function loadLevel(level, levelList) {
  //   for (let i = 0; i < levelList.Length; i++) {
  //     const img = new Image();
  //     const img2 = new Image();
  //     img.src =
  //       dirPath + level + "/" + levelList[i] + ".png";
  //     img2.src =
  //       dirPath + level + "/" + levelList[i] + "Answer.png";

  //     if (level <= 2) {
  //       const img3 = new Image();
  //       img3.src =
  //         dirPath + level + levelList[i] + "Pieces.png";
  //     }
  //   }
  // }

  useEffect(() => {
    // preLoadImages();
  }, []);

  useEffect(() => {
    setDisplay(0);
    setCheck(true);
    setNext(false);
    if (props.open) {
      props.changePdf(dirPath + level + "/" + "HELP.pdf");
      props.changeLU(dirPath + level + "/" + "LU.pdf")
    } else {
      // props.changePdf("/VisuallyDirectedFineMotor/AnimalShapesHELP.pdf");
    }
    
  }, [level, props.open]);

  function displayPicture(image) {
    setImage(image)
    setDisplay(1)
    console.log(image)
  }

  function disableNext()  {
    if (level === "Level1") {
      if (image === 4) {
        return true
      }
    } else if (level === "Level2") {
      if (image === 6) {
        return true
      }
    } else {
      if (image === 0) {
        return true
      }
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <OrigamiOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay="none"
          changeImage={setImage}
          imageBool={image}
          changePdf={props.changePdf}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {/* {display === 1 && ( */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "90%",
                width: "85%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: '100%'
                }}
              >
                <img
                  style={{ height: "100%" }}
                  src={dirPath + level + "/" + JSON.stringify(image) + ".png"}
                />
              </div>
            </div>
          {/* )} */}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture(image - 1);
            } else {
              props.openActivity(true);
            }
          }}
          disabled={image === 1 || image === 0}
        >
          Back
        </button>
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture(image + 1);
            } else {
              props.openActivity(true);
            }
          }}
          disabled={disableNext()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
