import AnimalOptions from "./AnimalOptions";
import { useEffect, useState } from "react";

export default function Level5_Tangrams(props) {
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("Circle1");
  var [dirPath, setDirPath] = useState("/VisuallyDirectedFineMotor/AnimalShapes/");

  const [level, setLevel] = useState("Level1");
  const [display, setDisplay] = useState(0);
  const [next, setNext] = useState(false);
  const [check, setCheck] = useState(true);

  // const Level4 = [
  //   "Crocodile",
  //   "Dinosaur",
  //   "Wombat",
  //   "Pengunin",
  //   "Frilled Lizard",
  //   "Emu",
  //   "Dog",
  // ];
  // const Level1 = ["Alien", "Tugboat", "House", "Bird", "Volcano"];
  // const Level2 = ["Bat", "Butterfly", "Fish", "Platypus", "Koala"];
  // const Level3 = ["Pelican", "Kangaroo", "Sea Tutle", "Sugar Glider", "Dingo"];

  // function preLoadImages() {
  //   for (let i = 0; i < 4; i++) {
  //     if (i === 0) {
  //       loadLevel(i, Level1);
  //     }
  //     if (i === 1) {
  //       loadLevel(i, Level2);
  //     }
  //     if (i === 2) {
  //       loadLevel(i, Level3);
  //     }
  //     if (i === 3) {
  //       loadLevel(i, Level4);
  //     }
  //   }
  // }

  // function loadLevel(level, levelList) {
  //   for (let i = 0; i < levelList.Length; i++) {
  //     const img = new Image();
  //     const img2 = new Image();
  //     img.src =
  //       dirPath + level + "/" + levelList[i] + ".png";
  //     img2.src =
  //       dirPath + level + "/" + levelList[i] + "Answer.png";

  //     if (level <= 2) {
  //       const img3 = new Image();
  //       img3.src =
  //         dirPath + level + levelList[i] + "Pieces.png";
  //     }
  //   }
  // }

  useEffect(() => {
    // preLoadImages();

    console.log("image HERE: ", image)
  }, []);

  useEffect(() => {
    if (image === "Circle1" || image === "Square1" || image === "Triangle1" || image === "Diamond1") {
      setDisplay(0);
      setNext(false);
    } else {
      setDisplay(1);
      setNext(true);
    }
    setCheck(true);
    // setNext(false);
    if (props.open) {
      props.changePdf(dirPath + "/" + level + "/" + image + "HELP.pdf");
    } else {
      props.changePdf("/VisuallyDirectedFineMotor/AnimalShapesHELP.pdf");
    }
    
  }, [level, image, props.open]);

  function displayPicture(buttonName) {
    if (buttonName !== "check") {
      setDisplay(1);
      setNext(true);
      setCheck(false);
    } else {
      setNext(false);
      setCheck(true);
      setDisplay(2);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <AnimalOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay="none"
          changeImage={setImage}
          imageBool={image}
          changePdf={props.changePdf}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {display === 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "90%",
                width: "85%",
              }}
            >
              {/* <div style={{ color: "white", fontSize: "3em" }}>
                Where do the blocks go?
              </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: '100%',

                }}
              >
                {/* <img
                  style={{
                    width: "40%",
                    backgroundColor: "white",
                    padding: "10px",
                  }}
                  src={
                    level === "Level1" || level === "Level2"
                      ? dirPath + "/" + level + "/" + image + "Pieces" + ".png"
                      : dirPath + "/" + level + "/Level3_4Pieces.png"
                  }
                /> */}
                <img
                  style={{ height: "100%" }}
                  src={dirPath + "/" + level + "/" + image + ".png"}
                />
              </div>
            </div>
          )}
          {display === 0 && (
            <div style={{ textAlign: "center", height: '100%' }}>
              <img
                style={{ height: "100%" }}
                src={dirPath + "/" + level + "/" + "Landing" + ".png"}
              />
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        {/* <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("check");
            } else {
              props.openActivity(true);
            }
          }}
          disabled={check}
        >
          Check
        </button> */}
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("next");
            } else {
              props.openActivity(true);
            }
          }}
          disabled={next}
        >
          Next
        </button>
      </div>
    </div>
  );
}
