import Button from "react-bootstrap/Button";
import React from "react";
import VisSeqButtons from "./visSeqButtons";

class Level4_VisualSequence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shape1: null,
      shape2: null,
      shape3: null,
      shape4: null,
      shape5: null,
      shape6: null,
      showShape1: null,
      showShape2: null,
      showShape3: null,
      showShape4: null,
      showShape5: null,
      showShape6: null,
      showAll: false,
      disableStart: false,
      disableCheck: true,
      diamond: <img src="/Visualization/shapes/yellowPara.png" width={"156px"} height={"52px"}/>,
      square: <img src="/Visualization/shapes/blackSquare.png" width={"76px"} height={"76px"}/>,
      triangle: <img src="/Visualization/shapes/blueTriangle.png" width={"155px"} height={"76px"}/>,
      greenTriangle: <img src="/Visualization/shapes/greenTriangle.png" width={"123px"} height={"56px"}/>,
      tiltDiamond: <img src="/Visualization/shapes/blackSquareTilted.png" width={"112px"} height={"112px"}/>,
      tiltDiamondLeft: <img src="/Visualization/shapes/redTriangleTilted.png" width={"226px"} height={"226px"}/>,
      sideDiamond: <img src="/Visualization/shapes/redTriangle.png" width={"214px"} height={"106px"}/>,
      tiltTriangleRight: <img src="/Visualization/shapes/greenTriangleTilted.png" width={"120px"} height={"120px"}/>,
      tiltTriangleLeft: <img src="/Visualization/shapes/blueTriangleTilted.png" width={"160px"} height={"160px"}/>,
      tiltSquare: <img src="/Visualization/shapes/yellowParaTilted.png" width={"150px"} height={"150px"}/>,
      white: <img src="/Visualization/shapes/white.png"/>,
      activity: "Normal",
      number: 3,
      displayTime: 1000,
      tempStyle1: "auto",
      tempStyle2: "auto",
      tempStyle3: "auto",
      tempStyle4: "auto",
      tempStyle5: "auto",
      tempStyle6: "auto",
      progression: "seq",
      chosenList: [],
    };
    this.startActivity = this.startActivity.bind(this);
    this.checkActivity = this.checkActivity.bind(this);
  }

  componentDidMount() {
    console.log(React.version);
  }

  changeAll(change) {
    if (change === "visible") {
      this.setState({
        shape1: this.state.showShape1,
        shape2: this.state.showShape2,
        shape3: this.state.showShape3,
        shape4: this.state.showShape4,
        shape5: this.state.showShape5,
        shape6: this.state.showShape6,
      });
    } else if (change === "flash") {
      this.setState(
        {
          shape1: this.state.showShape1,
          shape2: this.state.showShape2,
          shape3: this.state.showShape3,
          shape4: this.state.showShape4,
          shape5: this.state.showShape5,
          shape6: this.state.showShape6,
        },
        this.displayShapes(),
      );
    } else {
      this.setState({ shape1: this.state.white });
      this.setState({ shape2: this.state.white });
      this.setState({ shape3: this.state.white });
      this.setState({ shape4: this.state.white });
      this.setState({ shape5: this.state.white });
      this.setState({ shape6: this.state.white });
    }
  }

  startActivity(e) {
    if (e === 1) {
      this.changeAll("none");
    }
    this.setState({ ["tempStyle" + e]: "auto" });
    this.setState({ disableStart: true });
    this.setState({ disableCheck: true });
    if (e >= this.state.number + 1) {
      this.setState({ disableCheck: false });
      return;
    }
    this.setState({ showAll: false });
    // this.changeAll("hidden");
    if (
      this.state.activity === "Normal" ||
      this.state.activity === "SimNormal"
    ) {
      if (this.state.progression === "seq"){
        var shape = this.nonChosenImage(5)
      }
      var options = [
        this.state.diamond,
        this.state.triangle,
        this.state.square,
        this.state.sideDiamond,
        this.state.greenTriangle,
      ];
    } else {
      if (this.state.progression === "seq") {
        var shape = this.nonChosenImage(5)
      }
      var options = [
        this.state.tiltDiamond,
        this.state.tiltDiamondLeft,
        this.state.tiltTriangleRight,
        this.state.tiltSquare,
        this.state.tiltTriangleLeft,
      ];
    }

    if (this.state.progression === "seq") {
      // if (options[shape] === this.state.triangle) {
      //   console.log("we made it here");
      //   this.setState({ ["tempStyle" + e]: "100px" });
      // }
      this.setState({ ["shape" + e]: options[shape] });
      this.setState({ ["showShape" + e]: options[shape] });
      setTimeout(() => {
        this.setState({ ["shape" + e]: this.state.white });
        this.startActivity(e + 1);
      }, this.state.displayTime);
    } else {
      console.log("flashing all at once")
      console.log("list before entering: ", this.state.chosenList)
      for (let e = 1; e < this.state.number + 1; e++) {
        let shape = this.nonChosenImage(options.length)
        console.log("shape chosen: ", shape);
        console.log("list at this point: ", this.state.chosenList)
        this.setState({ ["showShape" + e]: options[shape] });
      }
      this.setState({chosenList: []})
      setTimeout(() => {
        this.changeAll("flash");
      }, 200);
    }
  }

  displayShapes() {
    setTimeout(() => {
      this.changeAll("hidden");
      this.setState({ disableCheck: false });
    }, this.state.displayTime);
  }

  checkActivity() {
    this.changeAll("visible");

    this.setState({ disableCheck: true });
    this.setState({ disableStart: false });
  }

  setNumber = (change) => {
    this.setState({ number: change });
    this.changeAll("none");
    this.setState({ disableCheck: true });
    this.setState({ disableStart: false });
    this.setState({chosenList: []})
  };

  changeTime = (change) => {
    this.setState({ displayTime: change });
    this.changeAll("none");
    this.setState({ disableCheck: true });
    this.setState({ disableStart: false });
  };

  changeActivity = (change) => {
    this.setState({ activity: change });
    this.changeAll("none");
    this.setState({ disableCheck: true });
    this.setState({ disableStart: false });
  };

  changeProgress = (change) => {
    this.setState({ progression: change });
    this.setState({chosenList: []})
  };

  nonChosenImage = (size) => {
    let random = Math.floor(Math.random() * size)
    console.log("random in nonChose image func: ", random)
    if (this.state.chosenList.length === this.state.number) {
      console.log("too large")
      let newList = [];
      newList.push(random);
      this.setState({chosenList: newList});
      return random
    }

    if (!this.state.chosenList.includes(random)) {
      let temp = this.state.chosenList;
      temp.push(random);
      this.setState({chosenList: temp});
      console.log("this is what chosenList should be: ", temp)
      return random
    }
    console.log("it exists so we repeat")
    return this.nonChosenImage(size)
  }

  render() {
    return (
      <div className="activity_box">
        <div className="activitySettingsButtonsContainer">
        <VisSeqButtons
          flashRate={this.changeTime}
          flashBool={this.state.displayTime}
          changeLevel={this.setNumber}
          levelBool={this.state.number}
          hiddenLetter={this.changeActivity}
          letterBool={this.state.activity}
          progressBool={this.state.progression}
          changeProgress={this.changeProgress}
        />
        </div>
        {
          this.props.open &&
        <div className="white_container">
          {this.state.showShape1 && (
            <div className="outerImgDiv" visibility={this.state.showShape1}>
              {/* <img
                // className="flashImage"
                src={this.state.shape1}
                style={{ transform: "scale(0.5)" }}
              /> */}
              {this.state.shape1}
            </div>
          )}
          {
            <div className="outerImgDiv" visibility={this.state.showShape2}>
              {/* <img
                // className="flashImage"
                src={this.state.shape2}
                style={{ transform: "scale(0.5)" }}
              /> */}
              {this.state.shape2}
            </div>
          }
          {
            <div className="outerImgDiv" visibility={this.state.showShape3}>
              {/* <img
                // className="flashImage"
                src={this.state.shape3}
                style={{ transform: "scale(0.5)" }}
              /> */}
              {this.state.shape3}
            </div>
          }
          {this.state.number > 3 && (
            <div className="outerImgDiv" visibility={this.state.showShape4}>
              {/* <img
                // className="flashImage"
                src={this.state.shape4}
                style={{ transform: "scale(0.5)" }}
              /> */}
              {this.state.shape4}
            </div>
          )}
          {this.state.number > 4 && (
            <div className="outerImgDiv" visibility={this.state.showShape5}>
              {/* <img
                // className="flashImage"
                src={this.state.shape5}
                style={{ transform: "scale(0.5)" }}
              /> */}
              {this.state.shape5}
            </div>
          )}
          {this.state.number > 5 && (
            <div className="outerImgDiv" visibility={this.state.showShape6}>
              <img 
                // className="flashImage" 
                src={this.state.shape6} 
              />
            </div>
          )}
        </div>
        }
        {/* {
          <div className="white_container_flex">
            <img src={this.state.shape1} />
            <img src={this.state.shape2} />
            <img src={this.state.shape3} />
            {this.state.number > 3 && <img src={this.state.shape4} />}
            {this.state.number > 4 && <img src={this.state.shape5} />}
            {this.state.number > 5 && <img src={this.state.shape6} />}
          </div>
        } */}
        <div className="button_section activityActionButtonsContainer">
          <button
          className="activityActionButton"
            disabled={this.state.disableCheck}
            onClick={() => 
              {
                if (this.props.open) {
                  this.checkActivity();
                } else {
                  this.props.openActivity(true);
                }}}
          >
            Check
          </button>
          <button
            className="activityActionButton"
            disabled={this.state.disableStart}
            onClick={() => {
              if (this.props.open) {
              this.startActivity(1);
              } else {
                this.props.openActivity(true);
              }
            }}
          >
            Start
          </button>
        </div>
      </div>
    );
  }
}

export default Level4_VisualSequence;
