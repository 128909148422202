import MazeOptions from "./MazeOptions";
import { useEffect, useState } from "react";

export default function Level6_Mazes(props) {
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("1");
  var [dirPath, setDirPath] = useState("/VisuallyDirectedFineMotor/Mazes/");

  const [level, setLevel] = useState("Level1");
  const [display, setDisplay] = useState(0);
  const [next, setNext] = useState(false);
  const [check, setCheck] = useState(true);

  // function preLoadImages() {
  //   for (let i = 0; i < 4; i++) {
  //     if (i === 0) {
  //       loadLevel(i, Level1);
  //     }
  //     if (i === 1) {
  //       loadLevel(i, Level2);
  //     }
  //     if (i === 2) {
  //       loadLevel(i, Level3);
  //     }
  //     if (i === 3) {
  //       loadLevel(i, Level4);
  //     }
  //   }
  // }

  function loadLevel(level, levelList) {
    for (let i = 0; i < levelList.Length; i++) {
      const img = new Image();
      const img2 = new Image();
      img.src =
        "/Visualization/Tangrams/" + level + "/" + levelList[i] + ".png";
      img2.src =
        "/Visualization/Tangrams/" + level + "/" + levelList[i] + "Answer.png";

      if (level <= 2) {
        const img3 = new Image();
        img3.src =
          "/Visualization/Tangrams/" + level + levelList[i] + "Pieces.png";
      }
    }
  }

  // useEffect(() => {
  //   preLoadImages();
  // }, []);

  useEffect(() => {
    setDisplay(0);
    setCheck(true);
    setNext(false);
    if (props.open === true) {
      props.changePdf(dirPath + "/" + level + "/" + image + "HELP.pdf");
    } else {
      props.changePdf("/VisuallyDirectedFineMotor/MazesHELP.pdf");
    }
  }, [level, image]);

  function displayPicture(buttonName) {
    if (buttonName !== "check") {
      setDisplay(1);
      setNext(true);
      setCheck(false);
    } else {
      setNext(false);
      setCheck(true);
      setDisplay(2);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <MazeOptions
          flashRate={setTime}
          flashBool={time}
          changeLevel={setLevel}
          levelBool={level}
          hiddenLetter={setDirPath}
          letterBool={dirPath}
          hiddenLetterDisplay="none"
          levelDisplay="none"
          changeImage={setImage}
          imageBool={image}
          changePdf={props.changePdf}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {display === 1 && (
            <div
              style={{
                textAlign: "center",
                backgroundColor: 'white',
                height: "100%",
                padding: '5px'
              }}
            >
              <img
                style={{ height:"100%" }}
                src={dirPath + "/" + level + "/" + image + ".png"}
              />
            </div>
          )}
          {display === 2 && (
            <div style={{ textAlign: "center",height: "100%", backgroundColor: 'white', padding:'5px' }}>
              <img
                style={{ height:"100%" }}
                src={dirPath + "/" + level + "/" + image + "Answer" + ".png"}
              />
            </div>
          )}
          {display === 0 && (
            <div style={{ textAlign: "center",height: "100%", backgroundColor: 'white', padding:'5px' }}>
              <img
                style={{ height:"100%" }}
                src={dirPath + "/" + level + "/"  + "Landing" + ".png"}
              />
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("check");
            } else {
              props.openActivity(true);
            }
          }}
          disabled={check}
        >
          Check
        </button>
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              displayPicture("next");
            } else {
              props.openActivity(true);
            }
          }}
          disabled={next}
        >
          Next
        </button>
      </div>
    </div>
  );
}
