export const ContactSection = () => {
  return (
    <div id="contactSectionContainer">
      <div style={{ paddingBottom: "2rem" }} className="sectionHeader">
        Contact
      </div>
      <div className="contactBodyText">
        Find us in Australia, halfway between Sydney and Melbourne. <br /> Reach
        us at info@piggybackjoy.com <br /> We welcome your comments and
        questions.
      </div>
    </div>
  );
};
