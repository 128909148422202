import "./ProviderInformation.css";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth.js";
import { AiOutlineLeft as BackIcon } from "react-icons/ai";
import { SyncLoader } from "react-spinners";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../base.js";

export default function ProviderInformation() {
  const { currentUser, setAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  async function handleCreateAccount(event) {
    event.preventDefault();
    setLoading(true);
    const userData = {
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        practice: event.target.nameOfPractice.value,
        street: event.target.address.value,
        city: event.target.city.value,
        country: event.target.country.value,
        zipCode: event.target.zipCode.value,
        email: currentUser.email,
        userID: currentUser.uid,
        userType: "provider",
      }
    let missingFields = []
    for (const [key, value] of Object.entries(userData)) {
      if(value===undefined || value===null || value==="" ){
        missingFields.push(key)
      }
    }
    let missingFieldsString=missingFields.join(", ")
    if(missingFields.length>0){
      alert("Values for the following fields are missing: "+missingFieldsString)
      setLoading(false);
      return;
    }
    else{
      await setDoc(
        doc(db, "users", currentUser.uid),
        userData,
        { merge: true },
      );
      setAuthUserData(userData)
      setLoading(false);
      navigate("/purchase");
    }
    
  }

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <>
      <div id="providerHeader">
        <div id="providerHeaderText">
          <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
            Provider Information
          </h1>
          <p style={{ fontSize: "17px" }}>
            Before purchasing Piggyback, please provide us with some information
            about your practice.
          </p>
        </div>
        <div onClick={() => navigate("/login")} id="providerScreenBackButton">
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Back</p>
        </div>
      </div>

      <form
        onSubmit={loading ? (e) => e.preventDefault() : handleCreateAccount}
        id="providerForm"
      >
        {/* ^ This is suppose to disable the form when we are in loading state */}
        <div className="providerFormRowContainer">
          <div className="providerInputContainer">
            <label for="firstName">First Name</label>
            <input type="text" name="firstName" />
          </div>
          <div className="providerInputContainer">
            <label for="lastName">Last Name</label>
            <input type="text" name="lastName" />
          </div>
        </div>
        <div className="providerFormRowContainer">
          <div className="providerInputContainer">
            <label for="nameOfPractice">Name Of Practice</label>
            <input
              style={{ width: "600px" }}
              type="text"
              name="nameOfPractice"
            />
          </div>
        </div>
        <div className="providerFormRowContainer">
          <div className="providerInputContainer">
            <label for="address">Practice Street Adress</label>
            <input style={{ width: "500px" }} type="text" name="address" />
          </div>
          <div className="providerInputContainer">
            <label for="city">City / State / Region / Province</label>
            <input style={{ width: "250px" }} type="text" name="city" />
          </div>
        </div>
        <div className="providerFormRowContainer">
          <div className="providerInputContainer">
            <label for="country">Country</label>
            <input style={{ width: "500px" }} type="text" name="country" />
          </div>
          <div className="providerInputContainer">
            <label for="zipCode">Zip / Postal Code</label>
            <input style={{ width: "250px" }} type="text" name="zipCode" />
          </div>
        </div>
        <div id="providerSubmitButtonContainer">
          <p>
            By hitting submit, I verify that the above information is correct.
          </p>
          <button type="submit" id="providerSubmitButton">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}
