import NextCheckButtons from "../Coding/nextCheckButtons";
import FindShapeButtons from "./FindShapeButtons";
import { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { propTypes } from "react-bootstrap/esm/Image";
// import FindShapes from "./framerMotion";

export default function Level1_FlashingPictures(props) {
  var [displayTime, setDisplayTime] = useState("visible");
  var [image, setImage] = useState("");
  var main = "0";
  var dirPath = "/EyeMovements/KoalaCopy/";
  const [speed, setSpeed] = useState(10);
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const [widthPath, setWidth] = useState(500);
  const [heightPath, setHeight] = useState(800);
  // const [imageHeightInt, setInt] = useState(200);
  var [start, setStart] = useState(false);
  const [margin, setMargin] = useState("0px");
  const [flash, setFlash] = useState(1000);

  const imageHeight = "200px";

  const firstTimeoutId = useRef(null);
  const secondTimeoutId = useRef(null);
  const thirdTimeoutId = useRef(null);

  const timeoutArray = []
  
  const [stop, setStop] = useState(false)

  const [running, setRunning] = useState(false);

  useEffect(() => {
    // setDisplayTime("hidden");
    setWidth(parentRef.current.clientWidth);
    if (parentRef.current) {
      let parentWidth = parentRef.current.clientWidth;
      let parentHeight = parentRef.current.clientHeight;
      setWidth(parentWidth);
      setHeight(parentHeight);
    }
    setStart(false);
    setMargin("0px");

    clearTimeout(firstTimeoutId.current);
    clearTimeout(secondTimeoutId.current);
    clearTimeout(thirdTimeoutId);
    clearAllTimeouts()
  }, [speed, parentRef, flash, props.open]);

  useEffect(() => {
    if (stop) {
      // Clear timeouts when start becomes false
      clearAllTimeouts()
      clearTimeout(firstTimeoutId.current);
      clearTimeout(secondTimeoutId.current);
    }
  }, [stop]);

  const containVar = {
    path: {
      x: [0, widthPath * 1, 0],
      transition: {
        duration: speed,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };

  const control = useAnimation();

  // var motionOptions = { duration: speed, repeat: Infinity, ease: "linear" };

  function clearAllTimeouts() {
    for (let i = 0; i < timeoutArray.length; i++) {
      clearTimeout(timeoutArray[i])
    }
    var id = setTimeout(function() {}, 0);

    while (id--) {
        clearTimeout(id);
    }

    setRunning(false)
  }

  function newSpeed(speed) {
    control.start({
      x: [0, widthPath, 0],
      transition: {
        duration: speed,
        repeat: Infinity,
        ease: "linear",
      },
    });
    setSpeed(speed);
  }

  function procedure(marginRecurse) {
    if (marginRecurse < heightPath - (parseInt(imageHeight) * 2)) {
      timeoutArray.push(setTimeout(() => {
        setStart(false);
        let newHeight = JSON.stringify(marginRecurse + parseInt(imageHeight)) + "px"
        setMargin(newHeight);
        procedure(marginRecurse + parseInt(imageHeight))
        setTimeout(() => {
          setStart(true);
        }, 500);
      }, speed * 1000));

    } else {
      setTimeout(() => {
        setStart(false)
        setStop(true)
        clearAllTimeouts()
        setMargin("0px")
      }, speed * 1000)
    }
    
  }

  function displayPicture() {
    setStart(true);
    setRunning(true)
    procedure(parseInt(margin));
    let parentWidth = parentRef.current.clientWidth;
    setWidth(parentWidth);
    setImage(main);
    // setDisplayTime("visible");
    repeat()
  }

  function repeat() {
    setImage(main)
    timeoutArray.push(setTimeout(() => {
      setImage(JSON.stringify(Math.floor(Math.random() * 3) + 1));
      timeoutArray.push(setTimeout(() => {
        repeat();
      }, flash))
    }, flash))
  }

  return (
    <div
      ref={parentRef}
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        // backgroundColor: "red",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <FindShapeButtons changeSpeed={newSpeed} speedBool={speed} flash={flash} changeFlash={setFlash}/>
      </div>
      {props.open && start && (
        <div style={{ height: "100%", width: "100%" }}>
          <motion.div
            variants={containVar}
            animate={{
              x: [0, widthPath * 0.8],
            }}
            transition={{ duration: speed, ease: "linear" }}
            style={{ width: "190px", height: imageHeight, marginTop: margin }}
          >
            <img
              // className="flashingPicsImage"
              src={dirPath + image + ".png"}
              style={{ visibility: displayTime, height: "100%", width: "100%" }}
            />
          </motion.div>
        </div>
      )}
      <NextCheckButtons
        displayFunc={displayPicture}
        displayCheck="none"
        open={props.open}
        openActivity={props.openActivity}
        disableNext={running}
      />
    </div>
  );
}
