import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function MazeOptions(props) {
  useEffect(() => {
    props.changePdf("/Visualization/Tangrams/Level1/TangramLevelOneHELP.pdf");
  }, []);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Type
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              props.changeImage("1");
            }}
            active={props.levelBool === "Level1"}
          >
            Collect the coins
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("1");
            }}
            active={props.levelBool === "Level2"}
          >
            Enter if you dare
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level3");
              props.changeImage("1");
            }}
            active={props.levelBool === "Level3"}
          >
            Escape if you can
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level4");
              props.changeImage("1");
            }}
            active={props.levelBool === "Level4"}
          >
            Wombat den
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => props.changeImage("1")}
            active={props.imageBool === "1"}
          >
            Level 1: Ensnare
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeImage("2")}
            active={props.imageBool === "2"}
          >
            Level 2: Mystify
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeImage("3")}
            active={props.imageBool === "3"}
          >
            Level 3: Disorient
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
