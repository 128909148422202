import RotatingFlippingButtons from "./rotatingFlippingButtons";
import { useState, useEffect, useRef } from "react";
import useSound from "use-sound";

export default function Level1_FlashingPictures(props) {
  var [displayTime, setDisplayTime] = useState("hidden");
  var [time, setTime] = useState(1000);
  var [image, setImage] = useState("");
  var [level, setLevel] = useState("Level2");
  var [triggered, setTriggered] = useState(false);
  var [beat, setBeat] = useState(false);
  var [loop, setLoop] = useState();
  var [imageSize, setImageSize] = useState("auto");
  var [display, setDisplay] = useState(2);
  var [correct, setCorrect] = useState("");
  var [rotation, setRotation] = useState("0deg");
  var [border1Color, setBorder1Color] = useState("white");
  var [border2Color, setBorder2Color] = useState("white");
  var [border3Color, setBorder3Color] = useState("white");
  var [border4Color, setBorder4Color] = useState("white");
  var [transform1, setTransform1] = useState("");
  var [image1, setimage1] = useState("");
  var [image2, setimage2] = useState("");
  var [image3, setimage3] = useState("");
  var [image4, setimage4] = useState("");
  var [move, setMove] = useState("rotate(0deg)");
  var [correctCount, setCorrectCount] = useState(0);
  var [totalCount, setTotalCount] = useState(1);
  var [imgWidth, setImgWidth] = useState();
  var [imgHeight, setImgHeight] = useState();
  var [stack, setStack] = useState("column");

  const rotateList = [
    "rotate(90deg)",
    "rotate(180deg)",
    "scaleX(-1)",
    "scaleY(-1)",
  ];

  const chosenNums = [];

  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();

  function preLoadImages() {
    for (let i = 0; i < 23; i++) {
      const img = new Image();
      img.src = "/Visualization/FindthePicture/" + i + ".jpg";
    }
  }

  const [play] = useSound("/clickSound.wav");

  const onImgLoad = ({ target: img }) => {
    if (img.naturalWidth > img.naturalHeight) {
      setImgWidth(img.naturalWidth * 0.45);
      setImgHeight(img.naturalWidth * 0.45);
    } else {
      setImgWidth(img.naturalHeight * 0.45);
      setImgHeight(img.naturalHeight * 0.45);
    }
  };

  useEffect(() => {
    preLoadImages();
  }, []);

  useEffect(() => {
    clearInterval(loop);
    setTriggered(false);
  }, [beat]);

  useEffect(() => {
    setDisplay(2);
  }, [time, move, level]);

  function chooseOption(option) {
    console.log("This is the total count at option selection: ", totalCount);
    chosenNums.length = 0;
    if (JSON.parse(option) === JSON.parse(correct)) {
      setDisplay(4);
      setTotalCount(totalCount + 1);
      setCorrectCount(correctCount + 1);
    } else {
      setDisplay(3);
      setTotalCount(totalCount + 1);
    }
    if (totalCount === 5) {
      setDisplay(5);
      setTotalCount(1);
    }
  }

  function findNonChosenImage() {
    let tempNum = JSON.stringify(Math.floor(Math.random() * 22) + 1);
    if (chosenNums.includes(tempNum)) {
      return findNonChosenImage();
    } else {
      chosenNums.push(tempNum);
      return tempNum;
    }
  }

  function displayPicture(buttonName) {
    setDisplayTime("hidden");
    setDisplay(2);
    if (totalCount === 1) {
      setCorrectCount(0);
    }
    setRotation("none");

    if (buttonName !== "check") {
      setTransform1(move);
      let randomIndex = Math.floor(Math.random() * 4) + 1;
      let randomNum = JSON.stringify(Math.floor(Math.random() * 22) + 1);
      setImage(randomNum);
      if (randomIndex === 1) {
        setCorrect("0");
        setimage1(randomNum);
        chosenNums.push(randomNum);
        setimage2(findNonChosenImage());
        setimage3(findNonChosenImage());
        setimage4(findNonChosenImage());
      } else if (randomIndex === 2) {
        setCorrect("1");
        setimage2(randomNum);
        chosenNums.push(randomNum);
        setimage1(findNonChosenImage());
        setimage3(findNonChosenImage());
        setimage4(findNonChosenImage());
      } else if (randomIndex === 3) {
        setCorrect("2");
        setimage3(randomNum);
        chosenNums.push(randomNum);
        setimage1(findNonChosenImage());
        setimage2(findNonChosenImage());
        setimage4(findNonChosenImage());
      } else {
        setCorrect("3");
        setimage4(randomNum);
        chosenNums.push(randomNum);
        setimage1(findNonChosenImage());
        setimage2(findNonChosenImage());
        setimage3(findNonChosenImage());
      }

      setTimeout(() => {
        setDisplay(0);
        setTimeout(() => {
          setDisplay(6);
        }, time);
      }, 200);
    } else {
      setRotation(move);
      setDisplay(10);
    }
  }

  function showChoices() {
    setDisplay(1);
    setTimeout(() => {
      imageRef1.current.style.visibility = "visible";
      imageRef2.current.style.visibility = "visible";
      imageRef3.current.style.visibility = "visible";
      imageRef4.current.style.visibility = "visible";
    }, 250);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div className="activitySettingsButtonsContainer">
        <RotatingFlippingButtons
          flashRate={setTime}
          flashBool={time}
          changeLevel={setMove}
          levelBool={move}
          useBeat={setBeat}
          beatBool={beat}
          imageBool={level}
          changeImage={setLevel}
          changeStack={setStack}
          hiddenLetterDisplay={"none"}
          hidePresentation={"none"}
          hideSecondLevel={"none"}
        />
      </div>
      {props.open && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%",
          }}
        >
          {display === 5 && (
            <div className="whiteTextStyle">
              You scored {correctCount} out of 5 Good Job!!
            </div>
          )}
          {display === 4 && <div id="correctText">Correct!</div>}
          {display === 3 && <div id="incorrectText">Incorrect!</div>}
          {display === 0 && (
            <img
              onLoad={onImgLoad}
              className="flashingPicsImage"
              src={"/Visualization/FindthePicture/" + image + ".jpg"}
              style={{
                height: "80%",
                width: "auto",
                maxWidth: "90%",
                transform: rotation,
              }}
            />
          )}
          {display === 1 && (
            <div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => chooseOption("0")}
                  style={{
                    margin: "10px",
                    display: "flex",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: border1Color,
                  }}
                >
                  <img
                    className="choiceImage"
                    src={"/Visualization/FindthePicture/" + image1 + ".jpg"}
                    style={{ visibility: "hidden", transform: transform1 }}
                    ref={imageRef1}
                  />
                </div>
                <div
                  onClick={() => chooseOption("1")}
                  style={{
                    margin: "10px",
                    display: "flex",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: border2Color,
                  }}
                >
                  <img
                    className="choiceImage"
                    src={"/Visualization/FindthePicture/" + image2 + ".jpg"}
                    style={{ visibility: "hidden", transform: transform1 }}
                    ref={imageRef2}
                  />
                </div>
                <div
                  onClick={() => chooseOption("2")}
                  style={{
                    margin: "10px",
                    display: "flex",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: border3Color,
                  }}
                >
                  <img
                    className="choiceImage"
                    src={"/Visualization/FindthePicture/" + image3 + ".jpg"}
                    style={{ visibility: "hidden", transform: transform1 }}
                    ref={imageRef3}
                  />
                </div>
                <div
                  onClick={() => chooseOption("3")}
                  style={{
                    margin: "10px",
                    display: "flex",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderColor: border4Color,
                  }}
                >
                  <img
                    className="choiceImage"
                    src={"/Visualization/FindthePicture/" + image4 + ".jpg"}
                    style={{ visibility: "hidden", transform: transform1 }}
                    ref={imageRef4}
                  />
                </div>
              </div>
            </div>
          )}
          {display === 10 && (
            <div
              style={{
                display: "flex",
                width: "70%",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: stack,
                height: "100%",
              }}
            >
              <img
                className="flashingPicsImage"
                src={"/Visualization/FindthePicture/" + image + ".jpg"}
                style={{ height: "45%", width: "auto" }}
              />
              <img
                className="flashingPicsImage"
                src={"/Visualization/FindthePicture/" + image + ".jpg"}
                style={{
                  height: "45%",
                  width: "auto",
                  transform: rotation,
                  border: "solid",
                  borderColor: "greenyellow",
                  borderWidth: "10px",
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="activityActionButtonsContainer">
        <button
          className="activityActionButton"
          disabled={display !== 4 && display !== 3}
          onClick={() => {
            if (props.open) {
              displayPicture("check");
            } else {
              props.openActivity(true);
            }
          }}
        >
          Check
        </button>
        <button
          className="activityActionButton"
          onClick={() => {
            if (props.open) {
              showChoices();
            } else {
              props.openActivity(true);
            }
          }}
          disabled={display !== 6}
        >
          Show Choices
        </button>
        <button
          className="activityActionButton"
          disabled={display === 1 || display === 6}
          onClick={() => {
            if (props.open) {
              displayPicture("next");
            } else {
              props.openActivity(true);
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
