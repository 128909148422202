import "./PatientInformation.css";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from v6
import { AuthContext } from "../Auth.js";
import { AiOutlineLeft as BackIcon } from "react-icons/ai";
import { SyncLoader } from "react-spinners";
import { doc, getDoc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../base.js";

export default function PatientInformation() {
  const { currentUser, setAuthUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [paitentCode, setPatientCode] = useState("");

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  async function handleCreateAccount() {
    setLoading(true);

    const licenceDocRef = doc(db, "licenses", paitentCode);
    const userDocRef = doc(db, "users", currentUser.uid);

    const [licenceDocSnap, userDocSnap] = await Promise.all([
      getDoc(licenceDocRef),
      getDoc(userDocRef),
    ]);

    // basic validation
    if (!licenceDocSnap.exists()) {
      alert("The license code you provided could not be found.");
      setLoading(false);
      return;
    }

    if (licenceDocSnap.data().used) {
      alert("The license code you provided has already been used.");
      setLoading(false);
      return;
    }

    // update the license document + user document
    const userData = {
      email: currentUser.email,
      userID: currentUser.uid,
      userType: "patient",
      license: paitentCode,
    }
    await Promise.all([
      setDoc(
        licenceDocRef,
        {
          patientID: currentUser.uid,
          patientEmail: currentUser.email,
          used: true,
          startDate: new Date(),
          endDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1),
          ),
        },
        { merge: true },
      ),
      setDoc(
        userDocRef,
        userData,
        { merge: true },
      ),
    ]);
    setLoading(false);
    setAuthUserData(userData)
    navigate("/activity");
  }

  if (loading) {
    return (
      <div className="loadingScreen">
        <SyncLoader color={"#e7b33e"} loading={loading} size={20} />
      </div>
    );
  }

  return (
    <>
      <div id="patientInformationHeader">
        <h1 style={{ fontFamily: "NexaBold", fontSize: "26px" }}>
          Patient Information
        </h1>
        <div onClick={() => navigate("/login")} id="patientScreenBackButton">
          <BackIcon size={20} />
          <p style={{ paddingTop: "14px", paddingLeft: "5px" }}>Back</p>
        </div>
      </div>
      <div id="patientInformationForm">
        <div id="patientInformationFormRowContainer">
          <label
            style={{
              fontFamily: "Mulish",
              fontSize: "18px",
              fontWeight: "700",
            }}
            for="lastName"
          >
            Please enter your access code.
          </label>
          <input
            onChange={(e) => setPatientCode(e.target.value)}
            value={paitentCode}
            style={{ width: "350px" }}
            type="text"
            name="accsessCode"
          />
          <button onClick={handleCreateAccount} id="patientScreenSubmitButton">
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
