import "./activity-table.css";
import { useRef, useState, createRef } from "react";
import { activityNames, tableObject, nameMapping } from "../data/tableData";

export function ActivityTable(props) {
  const [query, setQuery] = useState("");
  activityNames.map(() => createRef());
  function buildRefObject(){
    let refObj = {}
    Object.keys(tableObject).map((category,categoryIndex)=>{
      refObj[categoryIndex]={}
      Object.keys(tableObject[category]).map((level,lvlIndex)=>{
        refObj[categoryIndex][lvlIndex]={}
        tableObject[category][level].map((activity,activityIndex)=>{
          refObj[categoryIndex][lvlIndex][activityIndex]=createRef()
        })
      })
    })
    return refObj
  }
  const refObj = buildRefObject();
  console.log(refObj)
  function tableClickHandler(props,category,activityName){
      console.log("Table Click", category, activityName)
      let shortActivityName = activityName in nameMapping? nameMapping[activityName] : activityName.replaceAll(" ", "")
      let cat = category.replaceAll(" ","")
      props.changePdf(
        "/"+cat+"/" + shortActivityName + ".pdf",
      );
      props.changePdf2(
        "/"+cat+"/" + shortActivityName + "HELP.pdf",
      );
      props.closeDrawer(false);
    }

  function setMatches() {
    console.log("looking for", query)
    Object.keys(tableObject).forEach((category,categoryIndex)=>{
      Object.keys(tableObject[category]).forEach((level,lvlIndex)=>{
        tableObject[category][level].forEach((activity,activityIndex)=>{
          if (activity.toLowerCase().includes(query.toLowerCase())) {
            refObj[categoryIndex][lvlIndex][activityIndex].current.style.backgroundColor = "lightgreen";
          }
          else{
            refObj[categoryIndex][lvlIndex][activityIndex].current.style.backgroundColor = "inherit";
          }
        })
      })
    })
  }

  let lvl = 0
  let index = 0   
  console.log("my string: ", activityNames[0].replaceAll(" ", ""));
  return (
    <div id="activityTableWrapper">
      <div id="TSearchSection">
        <div
          className="activitySectionHeader"
          style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
        >
          Activities
        </div>
        <div style={{ display: "flex" }}>
          <input
            id="tableSearch"
            type="text"
            placeholder="Search Activites"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <button
            className="yellowButton"
            style={{ width: "90px" }}
            onClick={() => 
              setMatches()}
          >
            Enter
          </button>
        </div>
      </div>
      <div style={{ display: "flex" }}>
          {Object.keys(tableObject).map((category,categoryIndex) =>
          <table className="outerTable">
          <thead>
            <div style={{ padding: "0.5rem" }}>{category}</div>
          </thead>
          {Object.keys(tableObject[category]).map((level,lvlIndex) =>
          <tbody>
          <tr className={lvlIndex%2==1?"yellBG":""}>
            <th>{level}
            
            </th>
          </tr>
          {tableObject[category][level].map((activity,activityIndex) =>
          <>
          <tr
            ref={refObj[categoryIndex][lvlIndex][activityIndex]}
            onClick={() => {
              tableClickHandler(props,category,activity)
            }}
            className={lvlIndex%2==1?"yellBG":""}
          >
            <td>{activity}</td>
          </tr>
          </>
          )}
          </tbody>
          )}
          </table>)}
        
      </div>
    </div>
  );
}
