import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function ShadowPuppetOptions(props) {

  const dirPath = "/VisuallyDirectedFineMotor/AnimalShapes/"

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <Dropdown>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Type
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level1");
              props.changeImage("Dog");
            }}
            active={props.levelBool === "Level1"}
          >
            Basic
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.changeLevel("Level2");
              props.changeImage("Duck");
            }}
            active={props.levelBool === "Level2"}
          >
            Expert
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.FlashRateDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Progression
        </Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          {props.levelBool === "Level1" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Dog"); props.setPlace(0);}}
                active={props.imageBool === "Dog"}
              >
                One-Handed Dog
              </Dropdown.Item>
              
              <Dropdown.Item
                onClick={() => {props.changeImage("Butterfly"); props.setPlace(1);}}
                active={props.imageBool === "Butterfly"}
              >
                Butterfly
              </Dropdown.Item>
            </div>
          )}

          {props.levelBool === "Level2" && (
            <div>
              <Dropdown.Item
                onClick={() => {props.changeImage("Duck"); props.setPlace(0);}}
                active={props.imageBool === "Duck"}
              >
                Duck
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Dogs"); props.setPlace(1);}}
                active={props.imageBool === "Dogs"}
              >
                Dog
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Crab"); props.setPlace(2);}}
                active={props.imageBool === "Crab"}
              >
                Crab
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Jellyfish"); props.setPlace(3);}}
                active={props.imageBool === "Jellyfish"}
              >
                Jellyfish
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Kookaburra"); props.setPlace(4);}}
                active={props.imageBool === "Kookaburra"}
              >
                Kookaburra
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {props.changeImage("Croc"); props.setPlace(5);}}
                active={props.imageBool === "Croc"}
              >
                Crocodile
              </Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown style={{ display: props.hiddenLetterDisplay }}>
        <Dropdown.Toggle
          style={toggleStyle}
          variant="success"
          id="dropdown-basic"
        >
          Hidden Letter
        </Dropdown.Toggle>
        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPicturesLetters/")
            }
            active={
              props.letterBool === "/VisualThinking/FlashingPicturesLetters/"
            }
          >
            Use Hidden Letter
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              props.hiddenLetter("/VisualThinking/FlashingPictures/")
            }
            active={props.letterBool === "/VisualThinking/FlashingPictures/"}
          >
            Don't Use Hidden Letter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
