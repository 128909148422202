import Dropdown from "react-bootstrap/Dropdown";
import { toggleStyle, menuStyle } from "../../data/dropdownStyles";

export default function CodedMovementButtons(props) {
  return (
    <div style={{ display: "flex" }}>
      <Dropdown className="dropDownStyle">
        <Dropdown.Toggle style={toggleStyle}>Progression</Dropdown.Toggle>

        <Dropdown.Menu style={menuStyle}>
          <Dropdown.Item
            onClick={() => props.changeImage("Flying")}
            active={props.currImage === "Flying"}
          >
            Level 1: Eagle
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.changeImage("FlyingFish")}
            active={props.currImage === "FlyingFish"}
          >
            Level 2: Eagle's Nest
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
