export const smallWords = [
  "bake",
  "came",
  "case",
  "date",
  "fade",
  "face",
  "gate",
  "game",
  "gave",
  "lake",
  "lane",
  "late",
  "made",
  "make",
  "name",
  "pale",
  "rake",
  "rate",
  "safe",
  "sale",
  "same",
  "save",
  "take",
  "wake",
  "wave",
  "bike",
  "bite",
  "dine",
  "dive",
  "five",
  "fine",
  "hide",
  "line",
  "like",
  "life",
  "live",
  "loaf",
  "mine",
  "nice",
  "nine",
  "rice",
  "ride",
  "ripe",
  "size",
  "site",
  "side",
  "tide",
  "time",
  "wipe",
  "wise",
  "bone",
  "hole",
  "home",
  "hope",
  "lone",
  "note",
  "rode",
  "robe",
  "rope",
  "rose",
  "cute",
  "huge",
  "tune",
  "bait",
  "main",
  "paid",
  "rain",
  "wait",
  "bean",
  "been",
  "beat",
  "deep",
  "feet",
  "feel",
  "feed",
  "heat",
  "leap",
  "mean",
  "meat",
  "meet",
  "peel",
  "real",
  "reel",
  "read",
  "seem",
  "seam",
  "seen",
  "seat",
  "seed",
  "team",
  "boat",
  "coat",
  "soap",
];

export const bigWords = [
  "brave",
  "brake",
  "beach",
  "bleed",
  "breeze",
  "broke",
  "close",
  "chose",
  "chase",
  "crane",
  "chime",
  "choke",
  "creek",
  "drive",
  "dream",
  "flame",
  "feast",
  "freed",
  "float",
  "glide",
  "greed",
  "green",
  "greet",
  "grade",
  "grain",
  "grape",
  "grace",
  "plane",
  "plate",
  "plain",
  "please",
  "prize",
  "peach",
  "stain",
  "skate",
  "scale",
  "speak",
  "steam",
  "street",
  "stripe",
  "smile",
  "snake",
  "snail",
  "slope",
  "sheet",
  "sheep",
  "train",
  "trade",
  "tree",
  "these",
];
